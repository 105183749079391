import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {ILogEntry, IUserLogResult} from "../models/userLogs";

export class UserLogsService extends BaseService {
    private _repository = new Repository(endpoint)

    queries = {
        createLogs: () => this._repository.postQuery<void, IUserLogResult>(`v3/userlogs`),
        updateLogs: (userLogId: string) => this._repository.updateQuery<ILogEntry[], IUserLogResult>(`v3/userlogs/${userLogId}`),
        deleteLogs: (userLogId: string) => this._repository.removeQuery<IUserLogResult>(`v3/userlogs/${userLogId}`)
    }

    async createLogs() {
        return await this.queries.createLogs().add();
    }
    
    async updateLogs(userLogId: string, logEntries: ILogEntry[]) {
        return await this.queries.updateLogs(userLogId).update(logEntries);
    }

    async deleteLogs(userLogId: string) {
        return await this.queries.deleteLogs(userLogId).remove();
    }
}