interface Labels {
    "About": string
    "AboutThisTopic": string
    "Abuse": string
    "AccountActivation": string
    "ActivateAccount": string
    "Active": string
    "Activities": string
    "Activity": string
    "Add": string
    "AddAnotherCondition": string
    "AddAResponse": string
    "AddBlog": string
    "AddComment": string
    "AddCommunity": string
    "AddedToDiscussion": string
    "AddFile": string
    "AdditionalImages": string
    "AddLink": string
    "AddModPost": string
    "AddNewGroup": string
    "AddPhoto": string
    "Address": string
    "AddText": string
    "AddVideo": string
    "AddVimeoVideo": string
    "AddYoutubeVideo": string
    "All": string
    "AllImages": string
    "AllParticipants": string
    "AllParticipantsCamelCase": string
    "Analysis": string
    "And": string
    "AndNMorePeople": string
    "AnonymizeParticipants": string
    "Answer": string
    "AnswerQuestions": string
    "AnythingElseYouWouldLikeToAdd": string
    "Apply": string
    "Approve": string
    "AResponseIs": string
    "AreYouSureYouWantToUnsubscribe": string
    "AShortLinkOfTheCurrentPageWillBeSentToYourMobile": string
    "AtASpecificTime": string
    "Attach": string
    "AttachAndAddAnother": string
    "Attributes": string
    "Audio": string
    "AudioRecorderHint": string
    "AverageRank": string
    "AverageScoreWithColon": string
    "AvailableNextDay": string
    "AvailableSameDay": string
    "Back": string
    "BackToPinboard": string
    "BlankUniqueCodeLabel": string
    "Blog": string
    "Blogs": string
    "Blue": string
    "Border": string
    "BorderColor": string
    "BrushSize": string
    "Bummer": string
    "ButtonAlreadyPressed": string
    "ByPerson": string
    "ByTag": string
    "Camera": string
    "Cancel": string
    "CancelApproval": string
    "CancelUpload": string
    "CantAccessYourAccount": string
    "CentreJustified": string
    "ChangeAvatar": string
    "ChangePassword": string
    "Choices": string
    "Choose": string
    "ChooseTag": string
    "ChooseWhichGroupToAddThisCardTo": string
    "CleanUpPreviewData": string
    "ClearAll": string
    "ClearAnswer": string
    "ClearLocalStorage": string
    "ClearReport": string
    "ClickAnywhereOnTheImageBelowToPinAComment": string
    "ClickASingleLinkToLogIn": string
    "ClickedEmail": string
    "ClickHere": string
    "ClickHereIfYouDoNotWantToAddAVideoRespondByText": string
    "ClickHereToGoToN": string
    "ClickHereToRefresh": string
    "ClickHereToSeeAllImages": string
    "ClickOnImageToSelectIt": string
    "ClickToChange": string
    "ClickToContinue": string
    "ClickToUploadNewImage": string
    "Close": string
    "Closed": string
    "CloseSurvey": string
    "Color": string
    "Collapse": string
    "Comment": string
    "CommentHasBeenDeleted": string
    "CommentIsBeingEdited": string
    "CommentOnThisPost": string
    "Comments": string
    "Community": string
    "Complete": string
    "CompleteASurvey": string
    "Condition": string
    "Confirm": string
    "ConfirmClose": string
    "ConfirmLeave": string
    "ConfirmPassword": string
    "Connecting": string
    "ContentTextSearch": string
    "Continue": string
    "Contribute": string
    "CookieConsent_ButtonAccept": string
    "CookieConsent_ButtonAcceptAll": string
    "CookieConsent_ButtonCookieSettings": string
    "CookieConsent_ButtonDecline": string
    "CookieConsent_CookiePolicy": string
    "CookieConsent_DescriptionAnalyticsCookies": string
    "CookieConsent_DescriptionEssentialCookies": string
    "CookieConsent_DescriptionFunctionalityCookies": string
    "CookieConsent_Paragraph1": string
    "CookieConsent_Paragraph2": string
    "CookieConsent_Paragraph3": string
    "CookieConsent_Title": string
    "CookieConsent_TitleAnalyticsCookies": string
    "CookieConsent_TitleEssentialCookies": string
    "CookieConsent_TitleFunctionalityCookies": string
    "Country": string
    "Create": string
    "CreateAccount": string
    "CreateMyAccount": string
    "CreateNewBlogDescription": string
    "CreateNewCommunityDescription": string
    "CreateNewCommunitySurveyProjectDescription": string
    "CreateNewDiscussionDescription": string
    "CreateNewHomeworkDescription": string
    "CreateNewIdeationDescription": string
    "CreateNewImportDiscussionDescription": string
    "CreateNewSurveyProjectDescription": string
    "CreatePinBoard": string
    "Creation": string
    "Credits": string
    "Date": string
    "DateAdded": string
    "Day": string
    "Decimals": string
    "DefaultCamera": string
    "DefaultMic": string
    "Delete": string
    "DeleteEntity": string
    "Description": string
    "DeviceTooSmallForDiscussion": string
    "Digits": string
    "Discussion": string
    "DiscussionFAQs": string
    "DiscussionIntroduction": string
    "DiscussionIsOver": string
    "DiscussionIsStarted": string
    "DiscussionStart": string
    "Documents": string
    "Done": string
    "DoNotNeedSpecialTermsAndConditions": string
    "Download": string
    "DownloadImage": string
    "Draft": string
    "DraftSuccessfullyPublished": string
    "DragCardHere": string
    "DrawArrow": string
    "DrawCircle": string
    "DrawLine": string
    "DrawRectangle": string
    "DrawTriangle": string
    "DrawTwoSidedArrow": string
    "DropFilesHereToUpload": string
    "DropImagesHereToUpload": string
    "DropItemHere": string
    "DropItemHereToCreateNewGroup": string
    "DropVideoHere": string
    "DropVideosHereToUpload": string
    "EarliestTimeToShow": string
    "Edit": string
    "Email": string
    "EmailAddress": string
    "EmailBatch": string
    "Emoji": string
    "Empty": string
    "EnableSound": string
    "EndTime": string
    "EndTimePlaceholder": string
    "EnterAComment": string
    "EnterLinkBelow": string
    "EnterLiveDiscussion": string
    "EnterPinboardText": string
    "EnterStagingDiscussion": string
    "EnterYourMobileNumber": string
    "EntityDeleteConsequenceMessage": string
    "Eraser": string
    "ErrorDuringTagsLoading": string
    "ErrProcessing": string
    "ErrUpload": string
    "ErrVideoFilename": string
    "ErrVideoProbe": string
    "ErrVideoTranscode": string
    "Example": string
    "Expand": string
    "Explore": string
    "ExportToExcelForAnalysis": string
    "ExportToExcelForImport": string
    "FailureBecause": string
    "FailureCount": string
    "FailureMeanwhile": string
    "FailurePartialBecause": string
    "FailurePartialHowever": string
    "FailurePreview": string
    "FailureUpload": string
    "FilesUploaded": string
    "Files": string
    "Filter": string
    "Filters": string
    "Finalizing": string
    "FirstName": string
    "FirstUse": string
    "FixedPosition": string
    "Follow": string
    "Following": string
    "Font": string
    "FreeDrawingMode": string
    "From": string
    "Generate": string
    "GiveYourIdeaATitle": string
    "GotNewStatus": string
    "GoToCommunityHomePage": string
    "Green": string
    "HavingTroublesWithVideo": string
    "Heading1": string
    "Heading2": string
    "Heading3": string
    "Heatmap": string
    "HeatmapHint_Desktop": string
    "HeatmapHint_iPhone": string
    "HeatmapHint_iPhone_Zoomed": string
    "HeatmapHint_Mobile": string
    "HeatmapHint_Mobile_Zoomed": string
    "Height": string
    "HideAll": string
    "HideFullList": string
    "HideResourcesList": string
    "HighlightHasBeenDeleted": string
    "Highlight": string
    "History": string
    "HoldShiftKeyToSelectMultipleItems": string
    "Home": string
    "HomePage": string
    "Homework": string
    "HourShort": string
    "HoursShort": string
    "IAgree": string
    "IdeaCreation": string
    "Ideation": string
    "IdeationApprovedTag": string
    "IdeationStagingSite_Stage1Intro": string
    "IdeationStagingSite_Stage1Select": string
    "IdeationStagingSite_Stage2Intro": string
    "IdeationStagingSite_Stage2Select": string
    "IdeationStagingSite_Stage3Intro": string
    "IdeationStagingSite_Stage3Select": string
    "IdeationStagingSite_Stage4Intro": string
    "IdeationStagingSite_Stage4Select": string
    "IdeationStagingSiteNotes": string
    "IdentityPlaceholderEmailMobileNickname": string
    "IfYouAreHavingAProblemWithUploadingYourVideo": string
    "Ignored": string
    "Images": string
    "ImagesUploaded": string
    "Immediately": string
    "Import": string
    "In": string
    "IncludeHiddenQuestionsOnForms": string
    "Initializing": string
    "Introduction": string
    "InvalidURL": string
    "Instructions": string
    "IsOptional": string
    "ItemsCanNowBeRanked": string
    "ItemsToRank": string
    "ItMustHaveAtLeastNCharacters": string
    "ItMustHaveAtLeastNDigits": string
    "ItMustHaveAtLeastNSymbols": string
    "KeepMeLoggedIn": string
    "LanguageTranslationLabel": string
    "LastLoggedIn": string
    "LastName": string
    "LastUpdated": string
    "LastUpdateToLiveSuccessfullyUndone": string
    "LatestMessages": string
    "LatestTimeToShow": string
    "LeftJustified": string
    "LimitedTo": string
    "Lines": string
    "LinesAndArrows": string
    "Link": string
    "Links": string
    "LiveVersionDeletedSuccessfully": string
    "LiveVersionRetrievedSuccessfully": string
    "Loading": string
    "LoadMore": string
    "LoadNewComment": string
    "LoadNewComments": string
    "LoadNewReplies": string
    "LoadNewReply": string
    "LogIn": string
    "LoginId": string
    "LoginPage": string
    "LogOut": string
    "MadeComment": string
    "MadeReply": string
    "MakeActive": string
    "MakeVisible": string
    "Mandatory": string
    "MarkAllAsRead": string
    "MarkAsRead": string
    "MatchingTags": string
    "MemberSiteAvailableWithoutParticipantsMessage": string
    "MessageHasBeenDeleted": string
    "Microphone": string
    "Mine": string
    "Minutes": string
    "MinutesShort": string
    "MFA_ButtonLocked": string    
    "MFA_CodeInvalid": string
    "MFA_CodeRequest": string
    "MFA_CodeResend": string
    "MFA_CodeResendDelayed": string
    "MFA_CodeRetryAuthenticator": string
    "MFA_LabelRequestAuthenticator": string
    "MFA_LabelRequestAuthenticatorAlternative": string
    "MFA_LabelRequestEmail": string
    "MFA_LabelRequestEmailAlternative": string
    "MFA_LabelRequestLocked": string
    "MFA_LabelRequestLockedAuthenticator": string
    "MFA_LabelRequestSMS": string
    "MFA_LabelRequestSMSAlternative": string
    "MFA_LockoutMessage": string
    "MFA_MissingEmailAddress": string
    "MFA_MissingMobile": string
    "MFA_ParticipantTitle": string
    "MFA_ParticipantTitlePasswordLess": string
    "MFA_RememberDevice30days": string
    "MFA_SetupAuthenticator": string
    "MFA_SetupButtonRemove": string
    "MFA_SetupChoiceAuthenticator": string
    "MFA_SetupChoiceEmail": string
    "MFA_SetupChoiceSMS": string    
    "MFA_SetupDescription": string
    "MFA_SetupDescriptionPasswordLess": string
    "MFA_SetupEmail": string
    "MFA_SetupMultipleOptions": string
    "MFA_SetupMultipleOptionsPasswordLess": string
    "MFA_SetupOr": string
    "MFA_SetupQRCodeCopy": string
    "MFA_SetupRemoved": string
    "MFA_SetupSavedChoice": string
    "MFA_SetupSendByAuthenticator": string
    "MFA_SetupSendByEmail": string
    "MFA_SetupSendBySMS": string
    "MFA_SetupSMS": string
    "MFA_SetupTitle": string
    "MFA_SetupTitleModify": string
    "MFA_SetupTitleQRCode": string
    "MFA_Success": string
    "MFA_Title": string
    "MFA_TitleAccountLocked": string
    "MFA_TitleCodeExpired": string
    "MFA_TitleRequestAuthenticator": string
    "MFA_TitleRequestEmail": string
    "MFA_TitleRequestSMS": string
    "MFA_TitleSentEmail": string
    "MFA_TitleSentSMS": string
    "MFA_Verify": string
    "MobileNumber": string
    "Moderator": string
    "ModeratorNotes": string
    "Moderators": string
    "ModeratorsAndObservers": string
    "ModeratorTools": string
    "MoveDown": string
    "MoveNOW": string
    "MoveObject": string
    "MoveToThisTopicNOW": string
    "MoveUp": string
    "MustBeAgreedToBeforeContinuing": string
    "MuteSound": string
    "MyImages": string
    "MyProfile": string
    "Name": string
    "NComment": string
    "NComments": string
    "NeedSpecialTermsAndConditions": string
    "NeverLoggedIn": string
    "NewDiscussion": string
    "NewHomeworkTask": string
    "NewIdeation": string
    "NewSurvey": string
    "NewTopicGroup": string
    "Next": string
    "NextTopic": string
    "Nickname": string
    "NItems": string
    "NItemsToDrag": string
    "No": string
    "NobodyIsOnlineAtTheMoment": string
    "NoComments": string
    "NoCommentsToRead": string
    "NoImagesFound": string
    "None": string
    "NoReplies": string
    "NoTags": string
    "NotAvailableWhileTheDiscussionIsClosed": string
    "NotificationBadge": string
    "NotifyPeopleOfToDoListOnHours": string
    "NotNow": string
    "NotSupportedDevice": string
    "NoUserEvents": string
    "Now": string
    "NReplies": string
    "NReply": string
    "Observer": string
    "Observers": string
    "ObserverTools": string
    "Offline": string
    "OKGotIt": string
    "OneItem": string
    "OneItemToDrag": string
    "OneMoment": string
    "Online": string
    "Ooops": string
    "Opacity": string
    "Open": string
    "OpenLiveSurvey": string
    "OpenSurvey": string
    "OpenSurveyPreview": string
    "Optional": string
    "Or": string
    "Orange": string
    "Other": string
    "OtherComments": string
    "OtherParticipantsInTheDiscussionCanSeeThisPost": string
    "Others": string
    "OutdatedBrowserInfo": string
    "OutdatedBrowserLink": string
    "Pages": string
    "Participant": string
    "ParticipantMustChoose": string
    "ParticipantMustRank": string
    "Participants": string
    "Password": string
    "PasswordMustMeetTheFollowingRequirements": string
    "PasswordReset": string
    "People": string
    "PeopleAreTypingSmall": string
    "PeopleAreTypingBig": string
    "PeopleHaveReportedThisCommentAsAbusive": string
    "PeopleHaveReportedThisReplyAsAbusive": string
    "Percentage": string
    "Person": string
    "PersonIsTyping": string
    "PersonHasReportedThisCommentAsAbusive": string
    "PersonHasReportedThisReplyAsAbusive": string
    "PersonTags": string
    "PhoneNumber": string
    "Photo": string
    "PickImages": string
    "Pin": string
    "PinAComment": string
    "PinAddedDoYouWantToAddAnotherOne": string
    "PinnedComments": string
    "PinTasks": string
    "PixabayImageSearch": string
    "Pixels": string
    "PleaseAddYourFirstGroupBelow": string
    "PleaseAnswerQuestion": string
    "PleaseChooseOne": string
    "PleaseComplete": string
    "PleaseEnterYourEmailAddressToEnterTheDiscussion": string
    "PleaseWait": string
    "Poll": string
    "Polls": string
    "PollsCompleted": string
    "PortraitModeNotSupportedIPad": string
    "Post": string
    "PostCode": string
    "PostProc": string
    "Posts": string
    "PostText": string
    "Press": string
    "PressedNextTopicButtonToProceed": string
    "PreText": string
    "Preview": string
    "PreviewTheSiteAsIf": string
    "Private": string
    "PrivateMessage": string
    "PrivateParagraphText": string
    "PrivateParagraphTextDefaultValue": string
    "PrivatePost": string
    "ProblemsWithVideo": string
    "ProcessingVideo": string
    "Projects": string
    "Public": string
    "PublishNow": string
    "QuestionText": string
    "QuotaFail": string
    "Queue": string
    "RandomPosition": string
    "Rating": string,
    "ReCalculateEngagementStatisticsForProject": string,
    "RecContinue": string
    "ReceivedEmail": string
    "Recent": string
    "RecentPosts": string
    "RecentPostsAndReplies": string
    "RecentPostsShort": string
    "RecFinish": string
    "RecordAgain": string
    "RecordAudio": string
    "RecordIsNotSupported": string
    "RecordScreen": string
    "RecordUnavailable": string
    "RecordVideo": string
    "RecSpaceToToggle": string
    "RecStart": string
    "Red": string
    "Redo": string
    "RefreshTranslation": string
    "Remove": string
    "RemoveHighlight": string
    "Replies": string
    "Reply": string
    "ReplyHasBeenDeleted": string
    "ReplySuggestionThankYou": string
    "ReplySuggestionTellMeMore": string
    "ReplySuggestionGenerateAComment": string
    "ReplyTo": string
    "ReportAbuse": string
    "ReportedAsAbuse": string
    "Request": string
    "Requested": string
    "RequestACommentPrompt": string
    "RequireAPasswordToLogIn": string
    "Reset": string
    "ResetPassword": string
    "ResHi": string
    "ResLo": string
    "Resources": string
    "RespondentTagTestsAre": string
    "Responses": string
    "ResStd": string
    "Retry": string
    "ReturnToDashboard": string
    "ReturnToFrontPage": string
    "Review": string
    "Rewards": string
    "Save": string
    "SaveAndAddAnother": string
    "SaveAndNext": string
    "SaveSelection": string
    "Screener": string
    "ScreenerFail": string,
    "ScreenRecording": string
    "ScreenRecordingLabel1": string
    "ScreenRecordingLabel2": string
    "SearchContent": string
    "SearchForImage": string
    "Select": string
    "SelectAll": string
    "SelectAndAddAnother": string
    "SelectedImages": string
    "SelectFile": string
    "SelectOrDropFile": string
    "SelectYourUserImage": string
    "Send": string
    "SendingNow": string
    "SendResetEmail": string
    "SentTo": string
    "SetTheDisplayHeightTo": string
    "SetTheDisplayWidthTo": string
    "SetUpYourAccount": string
    "SharedContentParagraph": string
    "SharedContentParagraphDefaultValue": string
    "ShiftEnterToPost": string
    "Show": string
    "ShowChoices": string
    "ShowLabelsFor": string
    "ShowLess": string
    "ShowMore": string
    "ShowProfile": string
    "ShowResultsFor": string
    "ShowVisibilityTagsOnFormQuestions": string
    "SignUp": string
    "Size": string
    "SMS": string
    "SMSBatch": string
    "SomethingWentWrong": string
    "SortCards": string
    "SpecialMessageText": string
    "Staging": string
    "Staging_TagChecksApplied": string
    "Staging_TopicChecksApplied": string
    "Started": string
    "StartRecording": string
    "StartsIn": string
    "StartSurvey": string
    "StartSurveyLabel": string
    "StartTime": string
    "StartTimePlaceholder": string
    "State": string
    "Steps": string
    "StillLoading": string
    "Stopped": string
    "StopRecording": string
    "Street": string
    "Subject": string
    "Submit": string
    "SubmitVideo": string
    "SuggestedResponses": string
    "Survey": string
    "SurveyExternal": string
    "SurveyInternal": string
    "SurveyIntroduction": string
    "Surveys": string
    "SurveyStatus": string
    "SurveyStatusLabel": string
    "SurveyWillBe": string
    "Tag": string
    "TagDeleteConsequenceMessage": string
    "Tags": string
    "TagsForThisPerson": string
    "TakeThePoll": string
    "TapOnTheCardToSelectAnAnswer": string
    "TaskFinalize": string
    "TaskHas": string
    "TaskHasNoCommentsYet": string
    "TaskLanguageIs": string
    "TaskRetry": string
    "TaskTranscode": string
    "TaskUpload": string
    "TaskValidate": string
    "TaskWillCloseAtN": string
    "ThankYou": string
    "TheModeratorHasRequestedAResponse": string
    "TheModObsToolsWillBeOpenedInANewWindow": string
    "TheOrderingOfChoices": string
    "TheThumbnailIsStillBeingGenerated": string
    "ThisCommentHasBeenRead": string
    "ThisIsOurNextTopic": string
    "ThisActivityIsShownIf": string
    "ThisGroupIsShownIf": string
    "ThisPostIsAMessageOrQuestionToTheModerator": string
    "ThisPostIsOnlyVisibleToModeratorsAndObservers": string
    "ThisPostIsOnlyVisibleToYouAndParticipantsTaggedByTags": string
    "ThisPostIsOnlyVisibleToYouAndParticipantsYouHaveSelected": string
    "ThisPostIsOnlyVisibleToYouAndTheModerator": string
    "ThisQuestionIsShownIfPeopleHaveAllOfTheseTags": string
    "ThisQuestionIsShownIfPeopleHaveAtLeastOneOfTheseTags": string
    "ThisQuestionIsShownIfPeopleHaveNoneOfTheseTags": string
    "Tickets": string
    "Timeline": string
    "TimeRemaining": string
    "TimeTaken": string
    "TimesRankedAsFirst": string
    "TimesRankedAsLast": string
    "Title": string
    "To": string
    "ToDo": string
    "Topic": string
    "TopicCompletionTestsAre": string
    "TopicHasNoImages": string
    "TopicImages": string
    "Topics": string
    "TopicVisibilityTypes_AllParticipants": string
    "TopicVisibilityTypes_AllTags": string
    "TopicVisibilityTypes_AtleastOneTag": string
    "TopicVisibilityTypes_NoneOfTheseTags": string
    "ToPost": string
    "Total": string
    "TotalPosts": string
    "TotalPostsIncludingReplies": string
    "TotalPostsAndReplies": string
    "TotalTimeSpent": string
    "TotalUnreadComments": string
    "TranslatedInto": string
    "TranslateTo": string
    "TryAgain": string
    "Type": string
    "TypeYourSearchTextHere": string
    "Undo": string
    "UndoCheckIn": string
    "Unfollow": string
    "UnknownParticipantsCodesInputPlacholder": string
    "Unpin": string
    "Unread": string
    "UnreadComments": string
    "UnreadObserverComments": string
    "UnreadPostsAndReplies": string
    "Unselect": string
    "UnselectAll": string
    "UnsplashImageSearch": string
    "Unsubscribe": string
    "UnsubscribeFromProjectCancelText": string
    "UnsubscribeFromProjectDoneText": string
    "UnsubscribeFromProjectHelpText": string
    "Update": string
    "UpdateTag": string
    "UpdatingNow": string
    "UploadAudio": string
    "UploadAPhoto": string
    "UploadAVideo": string
    "UploadAVideoBlog": string
    "UploadDocuments": string
    "UploadFile": string
    "UploadFileHere": string
    "UploadImage": string
    "Uploading": string
    "UploadVideo": string
    "UploadYourVideoHere": string
    "UptoN": string
    "UpvotesRemaining": string
    "ViaMobile": string
    "Video": string
    "Videos": string
    "VideosUploaded": string
    "VideoTipText": string
    "VideoUploaded": string
    "ViewedFor": string
    "ViewForm": string
    "ViewResourcesList": string
    "Visibility": string
    "VisibleAfterN": string
    "VisibleBeforeN": string
    "VisibleBetweenMAndN": string
    "VisibleTo": string
    "WatchTheVideo": string
    "WebcamAllow": string
    "WebcamOoops": string
    "Welcome": string
    "WhatIsYourIdea": string
    "WhatWouldYouLikeToSay": string
    "Whisper": string
    "WhyDidYouVoteThisWay": string
    "Width": string
    "Will": string
    "WillBeSentOn": string
    "WillBeSentTo": string
    "WillNot": string
    "WithComments": string
    "WithoutTranslation": string
    "Yellow": string
    "Yes": string
    "YesDeleteThisEntity": string
    "YOU": string
    "YouAreNotFollowingAnybody": string
    "YouHaveNUpvotes": string
    "YouHaveNUpvotesAndMDownvotes": string
    "YouHaveReportedThisCommentAsAbusive": string
    "YouHaveReportedThisReplyAsAbusive": string
    "YouHaveToAddAtLeastNGroups": string
    "YouHaveToAddAtLeastOneGroup": string
    "YouHaveToSortAllCards": string
    "YouHaveToSortAtLeastNCards": string
    "YouHaveToSortAtLeastOneCard": string
    "YouHaveUsedMaxAmountOfCards": string
    "YourComments": string
    "YourNicknameWillBeVisibleToAllCommunityMembersAndModerators": string
    "YourProjectHasRunOutOfVideoAnalyticsCredits": string
    "YourRankings": string
    "YourRegisteredContactNumberWithUsIsN": string
    "ZoomOut": string
}

const labels: Labels = <any>{};

const config = (window as any).__wccconfig ?? {};
_(labels).extend(config.labels);

export default labels;