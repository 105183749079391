import { Culture } from 'interfaces/culture';
import ko, { PureComputed } from 'knockout';
import { FixedOffsetZone, Zone } from 'luxon';
import { JSONLanguageData } from 'models/languageData';
import UISettings from 'settings.ui';
import { getKOData } from './helpers/koData';
import { getSetting, setSetting } from './helpers/settings';
import ChaosTimezone from './models/timezones/chaos';
import { JSONTimezone } from './models/timezones/timezone';
import {apiTimeZoneBrowserMap} from "./models/apiTimeZoneBrowserMap";

function getSettingBoolean(name: string, defaultValue = false): boolean {
    return getSetting(name, defaultValue);
}

function getSettingStringOrNull(name: string, defaultValue?: string): string | undefined {
    return getSetting(name, defaultValue);
}

function getSettingString(name: string, defaultValue = ''): string {
    return getSetting(name, defaultValue);
}

function getSettingInt(name: string): number | undefined
function getSettingInt(name: string, defaultValue: number): number

function getSettingInt(name: string, defaultValue?: number) {
    const value = getSetting(name, defaultValue);
    return value != undefined ? parseInt(value) : undefined;
}

function getSitePostfix(isStaging: boolean, doNotTestPreviousTopicCompletion: boolean, doNotTestRespondentTags: boolean): string {
    var parts = [];

    if (isStaging && doNotTestPreviousTopicCompletion)
        parts.push('doNotTestPreviousTopicCompletion=true');

    if (isStaging && doNotTestRespondentTags)
        parts.push('doNotTestRespondentTags=true');

    return parts.join('&');
}

function toTimezone(jsonTimezone: JSONTimezone | undefined): Zone {
    return jsonTimezone == undefined ? FixedOffsetZone.utcInstance : new ChaosTimezone(jsonTimezone);
}

export class Settings {
    ui = new UISettings();

    get currentUser() { return getSetting('CurrentUser', {}) }
    set currentUser(value: any) { setSetting('CurrentUser', value) }

    get languageOptions() { return getSetting<JSONLanguageData | undefined>('LanguageOptions') }
    get projectTimezone() { return toTimezone(getSetting<JSONTimezone | undefined>('ProjectTimezone')) }
    
    get isSuperAdmin() { return getSettingBoolean('IsSuperAdmin') }
    get isAdmin() { return getSettingBoolean('isAdminSite') }
    get isModerator() { return this.currentUser.Status === 6 || this.currentUser.TaskStatus === 6 }
    get isStaging() { return getSettingBoolean('isStagingSite') }
    get isDashboard() { return getSettingBoolean('IsDashboard') }
    get isBrochure() { return getSettingBoolean('IsBrochure') }
    get isPolicy() { return getSettingBoolean('IsPolicy') }
    get isUnsubscribePage() { return getSettingBoolean('IsUnsubscribePage') }
    get isAnonymousDiscussion() { return getSettingBoolean('IsAnonymousDiscussion') }
    get isCookieConsentVersion2() { return getSettingBoolean('CookieConsentVersion2') }

    get launchCustomShell() { return getSettingBoolean('CustomShell') }

    get isCurrentUserAdmin() { return getSettingBoolean('IsCurrentUserAdmin') }
    get isIphone() { return getSettingBoolean('isIphone') }
    get isIE() { return getSettingBoolean('IsIE') }
    get doNotTestPreviousTopicCompletion() { return getSettingBoolean('doNotTestPreviousTopicCompletion') }
    get doNotTestRespondentTags() { return getSettingBoolean('doNotTestRespondentTags') }
    get doNotUseCreditCards() { return getSettingBoolean('DoNotUseCreditCards') }
    get discussionIsClosed() { return getSettingBoolean('discussionIsClosed') }
    get hasDiscussionMessage() { return getSettingBoolean('hasDiscussionMessage') }
    get SMSFunctionalityEnabled() { return getSettingBoolean('SMSFunctionalityEnabled') }
    get showAgreePage() { return getSettingBoolean('showAgreePage') }
    get disableSignalR() { return getSettingBoolean('DisableSignalR') }
    get disableContextMenu() { return getSettingBoolean('DisableContextMenu') }
    get demoWidgets() { return getSettingBoolean('DemoWidgets') }
    get v2Attachments() { return getSettingBoolean('v2Attachments') }
    get disableEmojis() { return getSettingBoolean('DisableEmojis') }
    get clipChampEnabled() { return getSettingBoolean('ClipChampEnabled') }
    get addPipeEnabled() { return getSettingBoolean('AddPipeEnabled') }
    get pixabayHDEnabled() { return getSettingBoolean('PixaBayHDEnabled') }
    get useNewVideoUpload() { return getSettingBoolean('UseNewVideoUpload') }
    get loggedInNavigation() { return getSettingBoolean('LoggedInNavigation') }

    isNotAuthenticated = ko.observable(getSettingBoolean('IsNotAuthenticated', true))
    requireAPassword = ko.observable(getSettingBoolean('RequireAPassword'))
    isLoggedIn = ko.observable(getSettingBoolean('IsLoggedIn'))
    agreedToTermsAndConditions = ko.observable(this.currentUser.AgreedToTermsAndConditions)
    unknownUserMode = ko.observable(false as boolean)
    isCommunity = ko.observable(getSettingBoolean('IsCommunity'))
    isCommunityRoot = ko.observable(getSettingBoolean('IsCommunityRoot'))
    isRTL = ko.observable(getSettingBoolean('isRTL'))
    forceMobileUI = ko.observable(false as boolean)
    allowContinuousSurveyEditing = ko.observable(false);

    isMaterialUIEnabled = ko.observable(getSettingBoolean('MaterialUIEnabled'));

    get wccAdminUrl() { return getSettingStringOrNull('WccAdminUrl') }
    get wccApiUrl() { return getSettingStringOrNull('WccApiUrl') }
    get WccCDNUrl() { return getSettingStringOrNull('WccCDNUrl') }
    get proxyTaskResources() { return getSettingBoolean('proxyTaskResources', false) }
    get proxyURL() { return getSettingStringOrNull('proxyURL') }
    get awsUrl() { return getSettingStringOrNull('AWSUrl') }
    get proxyTaskResourcesAwsOnly() {return getSettingBoolean('proxyTaskResourcesAwsOnly', false)}
    get proxyUrlAwsOnly() {return getSettingStringOrNull('proxyUrlAwsOnly')}
    get awsAccessKey() { return getSettingStringOrNull('AWSAccessKey') }
    get awsRegion() { return getSettingStringOrNull('AWSRegion') }
    get accountDNS() { return getSettingStringOrNull('CurrentAccountDNS') }
    get accountCountry() { return getSettingStringOrNull('AccountCountry') }
    get sitePostfix() { return getSitePostfix(this.isStaging, this.doNotTestPreviousTopicCompletion, this.doNotTestRespondentTags) };
    get avatarsFolder() { return getSettingStringOrNull('AvatarsFolder') }
    get widgetsThumbnailsFolder() { return getSettingStringOrNull('WidgetsThumbnailsFolder') }
    get videoProcessingThumbnail() { return getSettingString('videoProcessingThumbnail') }
    get taskSiteBaseURL() { return getSettingString('TaskSiteBaseURL') }
    get CDN_AWS_ImageURLPrefix() { return getSettingStringOrNull('CDN_AWS_ImageURLPrefix') }
    get AddPipe_Folder() { return getSettingStringOrNull('AddPipe_Folder') }
    get clipChampS3URLPrefix() { return getSettingStringOrNull('ClipChampS3URLPrefix') }
    get clipChampS3Folder() { return getSettingStringOrNull('ClipChampS3Folder') }
    get userfilesPath() { return getSettingStringOrNull('userFilesPath') }
    get noGravatarImageUrl() { return getSettingStringOrNull('NoGravatarImageUrl') }
    get clipchampURL() { return getSettingStringOrNull('ClipChampURL') }
    get dashboardUrl() { return getSettingStringOrNull('DashboardUrl') }
    
    sitePrefix = '';
    pixabayUrl = 'https://pixabay.com/api/'
    unsplashUrl = 'https://api.unsplash.com/'
    emojioneURL = 'https://cdnjs.cloudflare.com/ajax/libs/emojione/2.1.2'
    emojioneIconsURL = `${this.emojioneURL}/assets/png`
    aceAssetsURL = 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.11'
    contentboxAssetsURL = '/ContentBox/';
    emptyGuid = '00000000-0000-0000-0000-000000000000';    

    fontAwesomeUrl = ko.observable(getSettingStringOrNull('FontAwesomeUrl'))
    defaultTopicImageUrl = ko.observable('//s3-ap-southeast-2.amazonaws.com/wccadmin.com.au.usercontent/taskimagepresets/Appreciation.jpg')

    get discussionOpenDate() { return getSettingStringOrNull('DiscussionOpenDate') }

    get accountId() { return getSettingStringOrNull('AccountId') }
    get communityId() { return getSettingStringOrNull('CommunityId') }
    get communityPageId() { return getSettingStringOrNull('CommunityPageId') }
    get communityMasterPageId() { return getSettingStringOrNull('CommunityMasterPageId') }
    get communityDiscussionId() { return getSettingStringOrNull('CommunityDiscussionId') }
    get discussionId() { return getSettingStringOrNull('DiscussionId') }
    get taskId() { return getSettingStringOrNull('TaskId') }
    get parentTaskId() { return getSettingStringOrNull('ParentTaskId') }
    get taskMemberId() { return getSettingStringOrNull('TaskMemberId') || getSettingStringOrNull('itemId') }
    get topicId() { return getSettingStringOrNull('TopicId') }
    get personId() { return getSettingStringOrNull('PersonId') }
    get widgetId() { return getSettingStringOrNull('WidgetId') }
    get approvedTagId() { return getSettingStringOrNull('ApprovedTagId') }
    get discussionToken() { return getSettingStringOrNull('DiscussionToken') }
    get routeId() { return getSettingStringOrNull('RouteId') }
    get domainId() { return getSettingStringOrNull('domainId') }
    get domainUrlParam() { return this.domainId != null ? 'domainId=' + this.domainId : ''; }
    get emailCode() { return getSettingStringOrNull('EmailCode') }
    get importEntityId() { return getSettingStringOrNull('ImportEntityId') }
    get batchId() { return getSettingStringOrNull('BatchId') }
    get singleUseRegistrationAttributeTypes() { return <number[]>getSetting('SingleUseRegistrationAttributeTypes', []) }
    get mandatoryOnPersonAttributeTypes() { return <number[]>getSetting('MandatoryOnPersonAttributeTypes', []) }
    get questionTypesForFilters() { return <number[]>getSetting('QuestionTypesForFilters', []) }
    get attributeTypesForAttributeDataBrowse() { return <number[]>getSetting('AttributeTypesForAttributeDataBrowse', []) }
    get registrationFormId() { return <string>getSetting('RegistrationFormId', null) }
    get newSurveyQuestionDialogPageType() { return getSettingInt('NewSurveyQuestionDialogPageType', enums.NewSurveyQuestionDialogPageTypes.SurveyForm.value) }

    get captchaCode() { return getSettingStringOrNull('CaptchaCode') }
    get isCaptchaEnabled() { return getSettingBoolean('IsCaptchaEnabled', true) }
    
    get isDeveloper() { return getSettingBoolean('isDeveloper', false) }
    get isOperator() { return getSettingBoolean('isOperator', false) }
    
    get isQualzyAIEnabled() { return getSettingBoolean('isQualzyAIEnabled', false) }
    get isQualzyAIEnabledForAccount() { return getSettingBoolean('isQualzyAIEnabledForAccount', false) }
    
    get defaultAddressFormat() { return getSettingInt('DefaultAddressFormat', 0) }
    
    currentUserLoginToken = ko.observable(getSettingStringOrNull('CurrentUserLoginToken'))
    loginToken = ko.observable(getSettingStringOrNull('CurrentUserToken'))

    languageCode = getSettingString('LanguageCode', 'en');
    get defaultAccountLanguage() { return getSettingStringOrNull('DefaultAccountLanguage') }
    get translationLanguage() { return getSettingStringOrNull('TranslationLanguageCode') }
    get translationLanguageName() { return getSettingStringOrNull('TranslationLanguageName') }
    get siteTitle() { return getSettingStringOrNull('SiteTitle') };
    get themeCSS() { return getSettingString('ThemeCSS') }
    get topicVisibilityMomentJsDateTimeFormat() { return getSettingStringOrNull('TopicVisibilityMomentJsDateTimeFormat') }
    get dateTimeDisplayFormatFullForMoment() { return getSettingStringOrNull('DateTimeDisplayFormatFullForMoment') }

    get googleMapsApiKey() { return getSettingStringOrNull('GoogleMapsApiKey') }
    get page() { return getSetting('Page') }
    get initialRoute() { return getSettingStringOrNull('InitialRoute') }
    get addPipeAccountHash() { return getSettingStringOrNull('AddPipeAccountHash') }
    get addPipeEnvironmentId() { return getSettingStringOrNull('AddPipeEnvironmentId') }

    passwordPolicyData = ko.observable(getSetting('PasswordPolicyData', undefined))

    get discussionType() { return getSettingInt('DiscussionType') }
    get communityStandalonePageType() { return getSettingInt('CommunityStandalonePageType') }
    get personType() { return getSettingInt('PersonType') }
    get deploymentVersion() { return getSettingInt('DeploymentVersion', 1) }
    get queriesBatchMaxSize() { return getSettingInt('MaxAPIBatch', 10) }
    get tagDefaultVisibility() { return getSettingInt('NewTagDefaultValue') }
    get tagDefaultColor() { return getSettingString('TagDefaultColor', '#464646') }
    get currentUserToken() { return getSettingStringOrNull('CurrentUserToken') }
    get defaultWidgetAuthor() { return getSettingString('DefaultWidgetAuthor', 'Web Creator Suite') }
    get participantActivityWindow() { return getSettingInt('ParticipantActivityWindow', 30) }
    get sendParticipantActivityInterval() { return this.participantActivityWindow / 2 }    
    get operatorsForEachOperand() { return getSetting('OperatorsForEachOperand', {}) }    
    get attributeTypesSupportingRules() { return getSetting('AttributeTypesSupportingRules', []) }
    get dateFormatStrings() { return getSetting('DateFormatStrings', {}) }
    get hasProfileForms() { return getSettingBoolean('HasProfileForms', false) }
    
    get threadEditInterval() { return getSettingInt('threadsEditingInterval', 0) * 60000 }
    get threadDeleteInterval() { return getSettingInt('threadsDeletingInterval', 0) * 60000 }
    get eventsPerPage() { return getSettingInt('EventsPerPage', 20) }
    get maxPatternGroupsCount() { return getSettingInt('MaxPatternGroupsCount', 0) }
    get maxPatternsCount() { return getSettingInt('MaxPatternsCount', 0) }
    get maxPatternLength() { return getSettingInt('MaxPatternLength', 0) }
    get maxTagsCount() { return getSettingInt('MaxTagsCount', 0) }
    get maxUserContentImageSize() { return getSettingInt('maxUserContentImageSize') }
    get defaultTimeSelectionStep() { return getSettingInt('DefaultTimeSelectionStep', 0) }
    get chunkSize() { return getSettingInt('ChunkSize') }
    get cacheDisposeDelay() { return 15000 }
    get tableRowsPerPage() { return getSettingInt('TableRowsPerPage', 50) }
    get exportsSecurityMode() { return getSettingInt('ExportsSecurityMode', enums.ExportsSecurityModes.None.value) }

    mobileMaxSize = 500
    tabletMaxSize = 1020
    maxQueriesCount = 6
    topicRedirectDuration = 60
    defaultTopicIconColor = '#555555'
    availableViewportSizeToOverlay = 0.9    
    onlinePeopleDeleteDelay = 10000;
    onlineBeaconDelay = 5000
    videoProgressReportRate = 5000
    cacheForMinutes = 1440; //cache it for 24 hours i.e. 1440 minutes.THIS IS A REQUIREMENT FROM PIXABAY

    // max amount of email address we can send to
    emailSendLimit() { return getSettingInt('EmailSendLimit', 500) };
    
    maxTagValueDisplayLength = ko.observable(getSettingInt('MaxTagValueDisplayLength', 50))
    recentPostsIntervalForDiscussionHomePage = ko.observable(getSettingInt('RecentPostsIntervalForDiscussionHomePage', 24))
    pageEditorVersion = ko.observable(getSettingInt('PageEditorVersion', 1))

    get highlightColors() { return <object[]>getKOData('highlightColors', []) } 
    get systemMobilePhoneNumberCodes() { return <string[]>getSetting('SystemMobilePhoneNumberCodes', []) }
    get allowedUploadImageExtensions() { return <string[]>getSetting('allowedUploadImageExtensions', []) }
    get allowedUploadImageExtensionsForTopicThreads() { return <string[]>getSetting('allowedUploadImageExtensionsForTopicThreads', this.allowedUploadImageExtensions) }
    get allowedUploadVideoExtensions() { return <string[]>getSetting('allowedUploadVideoExtensions', []) }
    get allowedUploadAudioExtensions() { return getSetting('allowedUploadAudioExtensions', []) }
    get topicOrders() { return <object[]>getSetting('TopicOrders', []) }
    get alternateTopicReorderingTags() { return <object[]>getSetting('AlternateTopicReorderingTags', []) }
    get supportedCultures() { return <Culture[]>getSetting('SupportedCultures', []) }
    get months() { return <string[]>getSetting('Months', []) }
    get logsAuthToken() { return getSettingString('LogsAuthToken', '') }
    get logId() { return getSettingString('LogId', '') }
    get currentUserEmail() { return getSettingString('CurrentUserEmail', '') }
    get isSingleDomain() { return getSettingBoolean('isSingleDomain', false) }
    get isMultiDomain() { return getSettingBoolean('isMultiDomain', false) }
    get addBlankSurveyPageOnNewActivity() { return getSettingBoolean('AddBlankSurveyPageOnNewActivity', true) }
    get projectDeletionExpiration() { return getSettingInt('projectDeletionExpiration', 14) }
    get maxImagesInASurveyQuestion() { return getSettingInt('maxImagesInASurveyQuestion', 20) }
    get maxVideosInASurveyQuestion() { return getSettingInt('maxVideosInASurveyQuestion', 20) }
    get timeZoneBrowserMaps() { return <apiTimeZoneBrowserMap[]>getSetting('TimeZoneBrowserMaps', []) }
    get canHaveSecondaryDataInPeopleExport() { return getSettingBoolean('CanHaveSecondaryDataInPeopleExport', false) }

    get summariseableActivityTypes() { return <number[]>getSetting('SummariseableActivityTypes', []) }
    get dateTimeDisplayFormatTimeFirstForMomentJS() { return <string>getSettingString('DateTimeDisplayFormatTimeFirstForMomentJS', '') }
    
    asciiEmojiBlackList = ['%)', 'X)', 'B)', '8)', '#)']

    now: PureComputed<Date>
    timeAdjustment = ko.observable(0);
    postOnEnter = ko.observable(false)
    
    constructor() {
        const now = ko.observable(new Date());

        this.now = ko.pureComputed({
            read: () => new Date(now().getTime() + this.timeAdjustment()),
            write: value => now(value)
        });

        setInterval(() => this.now(new Date()), 1000);
    }
}

export var instance = new Settings();