import {injectable} from "inversify";
import {ILogEntry} from "./models/userLogs";

/**
 * The base interface for all types of log containers
 */
export interface ILogsContainer
{
    /**
     * Adds a log entry to the logs
     * @param logEntry The log entry to append to the log
     */
    addLogEntry: (logEntry: ILogEntry) => void;

    /**
     * Adds a log entry to the logs
     * @param logEntry The log entry string to append to the log
     */
    addEntry: (logEntry: string) => void;
    
    /**
     * Gets all the log entries
     * @constructor
     * @returns An array of all log entries
     */
    getAllLogs: () => ILogEntry[];
    
    /**
     * Gets the number of log entries we have
     * @constructor
     * @returns Number of log entries
     */
    logsCount: () => number;
}

@injectable()
export class LogsContainer implements ILogsContainer {
    private logEntries: ILogEntry[] = []

    addLogEntry(logEntry: ILogEntry) {
        this.logEntries.push(logEntry);
        //console.log(`${this.logsCount()} - (${logEntry.TimeOfLog}:${logEntry.Message})`);
    }

    addEntry(logEntry: string) {
        this.addLogEntry({
            TimeOfLog: system.getDotnetCompatibleUTCDate(),
            Message: `CLIENT-SIDE: ${logEntry}`
        });
    }
    
    getAllLogs(): ILogEntry[] {
        return this.logEntries;
    }
    
    logsCount(): number {
        return this.logEntries.length;
    }
}