interface IWCCResourcedErrorJson {
    LabelKey: string | null;
    MessageKey: string | null;
    ContentMessageKey: string | null
    Args: string[]
}

/**
 * This is a custom error that basically contains label, message and content message keys returned from the server
 * This is handy because we already have localised resources loaded on the client side and we just need to access it on the client side
 * without any dirty fiddling on the server
 */
export default class WCCResourcedError extends Error {
   
    private json: IWCCResourcedErrorJson;
    
    constructor(json: IWCCResourcedErrorJson) {
        super();
        this.json = json;
    }
    
    override toString() {
        let text = this.message;
        
        if (system.isNotNullOrEmpty(this.json.LabelKey)) {
            text = (labels as any)[this.json.LabelKey as string];
        }
        if (system.isNotNullOrEmpty(this.json.MessageKey)) {
            text = (messages as any)[this.json.MessageKey as string];
        }
        if (system.isNotNullOrEmpty(this.json.ContentMessageKey)) {
            text = (contentMessages[this.json.ContentMessageKey as string]);
        }
        
        //If the label or message has .NET string formatters ({0}, {1}), fix them with the supplied arguments
        if (system.isNotNullOrEmpty(text) && this.json.Args != null && this.json.Args.length > 0) {
            for (let i = 0; i < this.json.Args.length; i++) {
                text = text.replace(`{${i}}`, this.json.Args[i]);
            }
        }
        
        return text;
    }
}