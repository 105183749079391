import { EnumValue } from "./interfaces/enumValue";

interface Enums {
    AccountType: {
        AdHoc: EnumValue
        Community: EnumValue
        MysteryShopping: EnumValue
    }
    
    AccountStatus: {
        NeedsActivation: EnumValue
        Activated: EnumValue
        LockedOut: EnumValue
        Disabled: EnumValue
        Anonymized: EnumValue
    }

    ActivityIconStyles: {
        Image: EnumValue
        Default: EnumValue
        Square: EnumValue
        Circle: EnumValue
    }

    ActivityAvailabilities: {
        Available: EnumValue
        AvailableNextDay: EnumValue
        AvailableSameDay: EnumValue
        AvailableAfter: EnumValue
        AvailableBefore: EnumValue
        AvailableBetween: EnumValue
        Unavailable: EnumValue
    }

    ActivityTypes: {
        Undefined: EnumValue
        SocialPost: EnumValue
        SinglePage: EnumValue
        WatchAndAnswer: EnumValue
        DiaryEntry: EnumValue
        Journey: EnumValue
        PinTask: EnumValue
        CardSort: EnumValue
        CardScore: EnumValue
        Collage: EnumValue
        ImageUpload: EnumValue
        VideoUpload: EnumValue
        FileUpload: EnumValue
        ScreenRecording: EnumValue
        Information: EnumValue
        VideoPlayer: EnumValue
        MultiplePostGenerator: EnumValue
        ActivityGroup: EnumValue
        Import: EnumValue
        IdeationNew: EnumValue
        IdeationExisting: EnumValue
        IdeationHybrid: EnumValue
        AudioUpload: EnumValue
        ExternalSurvey: EnumValue
        CommunityRegistrationForm: EnumValue
        CommunityProfileForm: EnumValue
        Screener: EnumValue
    }

    AdvancedFilterItemTypes: {
        Unknown: EnumValue
        PersonTags: EnumValue
        PersonStatus: EnumValue
        ScreenerStatus: EnumValue
        SubGroup: EnumValue
        ProfileQuestions: EnumValue
        PersonType: EnumValue
        CommunityTags: EnumValue
        Activity: EnumValue
        ContentTextSearch: EnumValue
        ContentTags: EnumValue
        HighlightTags: EnumValue
        Translation: EnumValue
        PipelinesStatus: EnumValue
    }

    AdvancedFilterPersonStatusTypes: {
        NeverEmailed: EnumValue
        InLastImport: EnumValue
        SentScreener: EnumValue
        PassedScreener: EnumValue
        ScreenOut: EnumValue
    }

    Analysis2TopicFilterTypes: {
        None: EnumValue
        Visible: EnumValue
        Hidden: EnumValue
        Archived: EnumValue
        Polls: EnumValue
        All: EnumValue
    }

    apiAnalysisReportAggregationTypes: {
        FullTranscript: EnumValue
        IndividualPersonTranscripts: EnumValue
    }

    apiAnalysisReportContentTypes: {
        TranscriptOnly: EnumValue
        TranscriptAndPictures: EnumValue
    }

    apiAnalysisReportExcelSortingTypes: {
        Default: EnumValue
        ParticipantName: EnumValue
    }

    apiAnalysisReportGroupingTypes: {
        Topic: EnumValue
        Participants: EnumValue
    }

    apiAnalysisReportHighlightingTypes: {
        None: EnumValue
        HighlightedText: EnumValue
        SeparateSection: EnumValue
        HighlightedTextWithSeparateSection: EnumValue
    }

    apiAnalysisReportPeopleSelectionTypes: {
        CurrentPerson: EnumValue
        FilteredPeople: EnumValue
        AllPeople: EnumValue
    }

    apiAnalysisReportLinkRenderingTypes: {
        Exclude: EnumValue,
        Signed: EnumValue,
        Api: EnumValue
    }

    apiAnalysisReportStatsInclusionTypes: {
        StatsAndData: EnumValue
        StatsOnly: EnumValue
        DataOnly: EnumValue
    }

    apiAnalysisReportSurveyFormatTypes: {
        SinglePost: EnumValue
        QuestionByQuestion: EnumValue
    }

    apiAnalysisReportSurveySummaryTypes: {
        None: EnumValue
        NumericData: EnumValue
        TextData: EnumValue
    }

    apiAnalysisReportTagsGroupingTypes: {
        None: EnumValue
        PersonTags: EnumValue
        CommentTags: EnumValue
    }

    apiAnalysisReportTopicSelectionTypes: {
        CurrentTopic: EnumValue
        SelectedTopics: EnumValue
        CurrentTopicGroup: EnumValue
        SelectedTopicGroups: EnumValue
        AllTopics: EnumValue
        OverallRespondentStatistics: EnumValue
        FilteredTopics: EnumValue
    }

    apiAnalysisReportTranscriptTargetTypes: {
        Excel: EnumValue
        Word: EnumValue
    }

    apiAnalysisReportTranslationTypes: {
        Original: EnumValue
        Translated: EnumValue
        OriginalAndTranslated: EnumValue
    }

    apiAnalysisReportWordSortingTypes: {
        LatestFirst: EnumValue
        LatestLast: EnumValue
    }

    AnalyticsOptions: {
        None: EnumValue
        Translation: EnumValue
        SentimentAnalysis: EnumValue
        MachineTranscription: EnumValue
        HumanTranscription: EnumValue
    }

    apiAnalysis2ThreadsDateFilterTypes: {
        AllPosts: EnumValue
        PostedToday: EnumValue
        PostedLastWeek: EnumValue
    }

    apiCompressedFileFolderStructureTypes: {
        SingleLocation: EnumValue
        SeparateByTopic: EnumValue
        SeparateByTopicThenParticipantName: EnumValue
        SeparateByTopicThenAnonymousName: EnumValue
        SeparateByTags: EnumValue
    }
    
    apiCompressedFileTypes: {
        Images: EnumValue
        Videos: EnumValue
        ImagesAndVideos: EnumValue
        UploadedFiles: EnumValue
        AllFiles: EnumValue
    }
    
    apiContentTagSpecialType: {
        None: EnumValue
        VideoVerificationStart: EnumValue
        VideoVerificationAdvanced: EnumValue
        VideoVerificationBasic: EnumValue
        VideoVerificationFailed: EnumValue
        ApplyOnThread: EnumValue
    }

    apiImageQualityTypes: {
        Tiny: EnumValue
        Normal: EnumValue
        High: EnumValue
    }
    
    apiParticipantTopicCompletionActivityType: {
        Unknown: EnumValue
        Survey: EnumValue
        Video: EnumValue
        Heatmap: EnumValue
    }

    apiTimelineScope: {
        Discussion: EnumValue
        Topic: EnumValue
        Person: EnumValue
    }

    TopicCompletionStatusTypes: {
        Unknown: EnumValue
        Completed: EnumValue
        Unanswered: EnumValue
        NotVisibleYet: EnumValue
        NotShown: EnumValue
    }

    apiTopicOrderingTypes: {
        EndOfDiscussion: EnumValue
        Before: EnumValue
        After: EnumValue
        Default: EnumValue
    }

    apiUserEventType: {
        Comment: EnumValue
        Reply: EnumValue
        Email: EnumValue
        EmailClick: EnumValue
        Status: EnumValue
        Added: EnumValue
    }

    AttachmentTypes: {
        Image: EnumValue
        Video: EnumValue
        File: EnumValue
        Link: EnumValue
        CannedText: EnumValue
        SurveyImage: EnumValue
        VirtualPinBoardImage: EnumValue
        VirtualPinBoardBackground: EnumValue
        ShowreelIntro: EnumValue
        ShowreelCredits: EnumValue
        ShowreelBackground: EnumValue
        ShowreelWatermark: EnumValue
        SurveyAnswerImage: EnumValue
        SurveyAnswerVideo: EnumValue
        SurveyAnswerFile: EnumValue
        ActivityInstructionsImage: EnumValue
        VimeoCode: EnumValue
        YouTubeCode: EnumValue
        IdeaRatingImage: EnumValue
        Audio: EnumValue
        SurveyAnswerAudio: EnumValue
        ShowreelTitleBackground: EnumValue
        RatingMatrixBackground: EnumValue
        ProjectToDoAttachment: EnumValue
        TopHeroImage: EnumValue
        ActivityImageSmall: EnumValue
        ActivityImageMedium: EnumValue
        ActivityImageBig: EnumValue
        ThemeImage: EnumValue
        ProjectGuide: EnumValue
        VideoPresentationVideo: EnumValue
    }

    AuthenticatorResultTypes: {
        Success: EnumValue
        EmptyPassword: EnumValue
        WrongEmail: EnumValue
        WrongMobilePhone: EnumValue
        AccountIsLocked: EnumValue
        AccountIsNotActivated: EnumValue
        PasswordNotSet: EnumValue
        WrongPassword: EnumValue
        PolicyFailure: EnumValue
        WrongNickname: EnumValue
        AccountIsDisabled: EnumValue
    }

    BrowseFilterExtraFields: {
        ThreadsCount: EnumValue
        TimeSpent: EnumValue
        EmailsSent: EnumValue
        LastEmailSent: EnumValue
        SMSSent: EnumValue
        LastSMSSent: EnumValue
        LastPost: EnumValue
        CommentsCount: EnumValue
        RepliesCount: EnumValue
        GroupTitle: EnumValue
        PeopleCount: EnumValue
        ActivitiesCount: EnumValue
        ThoroughDescription: EnumValue
        DiscussionHasAttachments: EnumValue
        TaskMemberExternalId: EnumValue
        CreatorEmailAddress: EnumValue
        CreatorMobilePhone: EnumValue
        PersonLastActivityDate: EnumValue
        TopicsCompletionSummary: EnumValue
        CompletePercentage: EnumValue
        LastOpened: EnumValue
    }

    BrowseFilterExtraFieldsWithParams: {
        TopicCommentsCount: EnumValue
        TopicRepliesCount: EnumValue
    }

    BrowseFilterExtraJoinsWithParams: {
        LastOpened: EnumValue
    }

    BrowseFilterGroupModes: {
        Condition: EnumValue
        AndGroup: EnumValue
        OrGroup: EnumValue
        WrappedGroup: EnumValue
        ComplexField: EnumValue
    }

    BrowseFilterOperators: {
        None: EnumValue
        Equal: EnumValue
        NotEqual: EnumValue
        In: EnumValue
        NotIn: EnumValue
        Less: EnumValue
        LessOrEqual: EnumValue
        Greater: EnumValue
        GreaterOrEqual: EnumValue
        Between: EnumValue
        NotBetween: EnumValue
        IsNull: EnumValue
        IsNotNull: EnumValue
        BeginsWith: EnumValue
        NotBeginWith: EnumValue
        Contains: EnumValue
        NotContains: EnumValue
        EndsWith: EnumValue
        NotEndsWith: EnumValue
        IsEmpty: EnumValue
        IsNotEmpty: EnumValue
        IsWithinMetres: EnumValue
        IsNotWithinMetres: EnumValue
        ReverseIn: EnumValue
    }

    BrowseFilterSortDirection: {
        Ascending: EnumValue
        Descending: EnumValue
    }

    BrowseFilterSortTargets: {
        None: EnumValue
        FirstName: EnumValue
        LastName: EnumValue
        AnonymousName: EnumValue
        CreateDate: EnumValue
        EmailAddress: EnumValue
        Age: EnumValue
        Gender: EnumValue
        TimeSpent: EnumValue
        TopicsCompleted: EnumValue
        UnreadComments: EnumValue
        ThreadsCount: EnumValue
        TopicOrder: EnumValue
        LastActivityDate: EnumValue
        Title: EnumValue
        EditDate: EnumValue
        DiscussionIsGroupped: EnumValue
        VotesCount: EnumValue
        ThreadIsPinned: EnumValue
        ProjectsCount: EnumValue
        DiscussionGroupTitle: EnumValue
        ProjectOpeningDate: EnumValue
        ProjectOnlineStatus: EnumValue
        ThreadAttachmentDuration: EnumValue
        TotalMentions: EnumValue
        EntityText: EnumValue
        IsPinned: EnumValue
        QuestionUniqueCode: EnumValue
        WordsDensity: EnumValue
        AverageConfidence: EnumValue
        UnclickedStatus: EnumValue
        CompletePercentage: EnumValue
        LastOpened: EnumValue
    }

    BrowseFilterTargetFieldDataTypes: {
        Unknown: EnumValue
        String: EnumValue
        Integer: EnumValue
        Genders: EnumValue//this is handled as an int
        Date: EnumValue
        Bool: EnumValue
        Decimal: EnumValue
        Geography: EnumValue
        IntBitField: EnumValue
        Guid: EnumValue
        NestedQuery: EnumValue
    }

    BrowseFilterTargetFields: {
        None: EnumValue
        SurveyId: EnumValue
        DataText: EnumValue
        DataText1: EnumValue
        DataText2: EnumValue
        DataText3: EnumValue
        DataText4: EnumValue
        DataText5: EnumValue
        DataInt: EnumValue
        DataDecimal: EnumValue
        DataDate: EnumValue
        DataBool: EnumValue
        LoopingId: EnumValue
        QuestionId: EnumValue
        DataName: EnumValue
        Country: EnumValue
        DiscussionId: EnumValue
        PersonTags: EnumValue
        PersonTagsExcluded: EnumValue
        PersonStatus: EnumValue
        Age: EnumValue
        Region: EnumValue
        InLastImport: EnumValue
        InvitationSendDate: EnumValue
        ReminderSendDate: EnumValue
        ScreenerSendDate: EnumValue
        HasNoScreenerResponse: EnumValue
        PersonCommunityTags: EnumValue
        PersonCommunityTagsExcluded: EnumValue
        ParentThreadId: EnumValue
        ThreadPostDate: EnumValue
        ThreadsCount: EnumValue
        InternalSurvey: EnumValue
        QuickSearch: EnumValue
        AccountId: EnumValue
        IsAdmin: EnumValue
        TopicIsCompleted: EnumValue
        TopicIsNotCompleted: EnumValue
        IdeationIsCompleted: EnumValue
        IdeationIsNotCompleted: EnumValue
        HasThreads: EnumValue
        TopicsCompletionIsUpToDate: EnumValue
        TimeSpent: EnumValue
        QuickSearchNoTags: EnumValue
        IsNeverEmailed: EnumValue
        PersonAdded: EnumValue
        LastEmailSent: EnumValue
        LastSMSSent: EnumValue
        IsNeverSMSed: EnumValue
        ThreadPostedByPersonId: EnumValue
        ThreadInTopicId: EnumValue
        DirectDiscussionId: EnumValue
        TopicThreadId: EnumValue
        IsStaging: EnumValue
        IsHighlight: EnumValue
        HasPictureAttachment: EnumValue
        HasVideoAttachment: EnumValue
        IsTopicThreadRead: EnumValue
        IsTopicThreadReadMod: EnumValue
        ActivityInTopicId: EnumValue
        PersonId: EnumValue
        HasSentimentData: EnumValue
        TopicIsArchived: EnumValue
        TopicIsHidden: EnumValue
        ThreadContent: EnumValue
        ContentTags: EnumValue
        ContentTagsExcluded: EnumValue
        HighlightTags: EnumValue
        HighlightTagsExcluded: EnumValue
        IsHeatMapPin: EnumValue
        ThreadInTopicContentType: EnumValue
        ParentThreadPostedByPersonId: EnumValue
        OverallSentiment: EnumValue
        SentimentSentenceScore: EnumValue
        HasComments: EnumValue
        HasCommentsToday: EnumValue
        HasCommentsLastWeek: EnumValue
        HighlightColorId: EnumValue
        ThreadInTopicType: EnumValue
        HasCommentsInTopicContentType: EnumValue
        TextAnalysisSentenceScore: EnumValue
        HasCommentsWithSentiment: EnumValue
        DomainId: EnumValue
        HasUnreadCommentsByMod: EnumValue
        DiscussionTitle: EnumValue
        DiscussionQuickSell: EnumValue
        DiscussionOnlineStatus: EnumValue
        DiscussionVisibleAfter: EnumValue
        DiscussionVisibleBefore: EnumValue
        DiscussionCreateDate: EnumValue
        DiscussionEditDate: EnumValue
        DiscussionLastActivityDate: EnumValue
        DiscussionMostRecentlyModifiedTen: EnumValue
        DiscussionType: EnumValue
        TopicTitle: EnumValue
        TopicQuickSell: EnumValue
        TopicType: EnumValue
        DiscussionTopicId: EnumValue
        ActivityIsArchived: EnumValue
        ActivityIsHidden: EnumValue
        PeoplePostedInTopicContentType: EnumValue
        AttachmentIsEmbeddedImage: EnumValue
        ProjectId: EnumValue
        IsModeratorAnywhere: EnumValue
        IsObserverInTheseProjects: EnumValue
        ScreenerStarted: EnumValue
        ScreenerIsAnythingButStarted: EnumValue
        CanBeAnonymized: EnumValue
        IsStagingPerson: EnumValue
        GroupId: EnumValue
        HasVideoPosts: EnumValue
        HasSentimentPosts: EnumValue
        HasCardScorePosts: EnumValue
        HasCardScoreAnswer: EnumValue
        HasCardSortPosts: EnumValue
        HasCardSortPostsInDomain: EnumValue
        HasCardSortComments: EnumValue
        HasCardSortCommentsInDomain: EnumValue
        HasVideoTranscriptionAttachments: EnumValue
        IsVideoAttachment: EnumValue
        HasVideoTranscriptionPosts: EnumValue
        HasPicturePosts: EnumValue
        TopicHasUnreadComments: EnumValue
        LogsLevel: EnumValue
        LogsSource: EnumValue
        LogId: EnumValue
        ProjectCreatorEmail: EnumValue
        HasAudioTranscription: EnumValue
        HasAudioTranscriptionPosts: EnumValue
        IsAudioAttachment: EnumValue
        IsTranscribedVideoAttachment: EnumValue
        IsTranscribedAudioAttachment: EnumValue
        ActivityDomainId: EnumValue
        HasCommentsInDomainActivity: EnumValue
        HasCommentsWithSentimentInDomainActivity: EnumValue
        ThreadInDomainActivity: EnumValue
        HasCommentsOrReplies: EnumValue
        ProjectAccountId: EnumValue
        ProjectDeletionMode: EnumValue
        IsDeletedPerson: EnumValue
        IsDeletedTaskMember: EnumValue
        HasVideoAnalysisPosts: EnumValue
        HasVideoAnalysis: EnumValue
        AttachmentTranscript: EnumValue
        HasVideoAnalysisPostsInDomain: EnumValue
        IsVideoAnalyticsAttachment: EnumValue
        UserFileThreadAttachmentId: EnumValue
        VideoAttachmentIsWatched: EnumValue
        ProfileQuestionSurveyId: EnumValue
        ProfileQuestionAccountId: EnumValue
        DataToolsEntity: EnumValue
        VideoAnalyticsEntity: EnumValue
        VideoAnalyticsEntityExcluded: EnumValue
        UserFileThreadAttachmentClipId: EnumValue
        ThreadAttachmentId: EnumValue
        VideoAnalyticsClipText: EnumValue
        TextAnalyticsEntityIsHidden: EnumValue
        VideoAnalyticsEntityText: EnumValue
        VideoAnalyticsEntityClipId: EnumValue
        VideoAnalyticsEntityId: EnumValue
        IsUnprocessedVideoAnalyticsAttachment: EnumValue
        HasUnprocessedVideos: EnumValue
        HasUnprocessedVideosInDomain: EnumValue
        HasUnprocessedPosts: EnumValue
        HasUnprocessedPostsInDomain: EnumValue
        AttachmentsWithVideoTranscription: EnumValue
        AttachmentsWithoutVideoTranscription: EnumValue
        AttachmentsWithHumanTranscriptionPending: EnumValue
        AttachmentsWithMachineTranscriptionOnly: EnumValue
        AttachmentsWithHumanTranscription: EnumValue
        VideoAnalyticsVideoIsIgnored: EnumValue
        ClipIsAssigned: EnumValue
        RelatedEntityId: EnumValue
        TextAnalyticsSentenceScore: EnumValue
        MayHaveVideoAnalysis: EnumValue
        QuickSearchThreadPersonTags: EnumValue
        MayHaveSentiment: EnumValue
        PersonDomainIds: EnumValue
        ActivityType: EnumValue
        TopicHasSurveyStat: EnumValue
        TopicHasSurveyStatInDomain: EnumValue
        IsSurveyLikeActivity: EnumValue
        QuickSearchSurvey: EnumValue
        PeopleHasSurveyStatPosts: EnumValue
        PeopleHasSurveyStatPostsInDomain: EnumValue
        IfPeopleHasSurveyStatPosts: EnumValue
        TextAnalyticsEntityRelatedEntityId: EnumValue
        VideoAnalyticsThemeTitle: EnumValue
        VideoAnalyticsRelatedEntityText: EnumValue
        EntityUsageIsNotInClip: EnumValue
        VideoAnalyticsThemeThemeId: EnumValue
        TotalMentionsForHAVING: EnumValue
        TotalMentionsForHAVINGInDataTools: EnumValue
        TopicLastActivityIn: EnumValue
        HasCommentsInActivity: EnumValue
        ProfileAnswer: EnumValue
        DataTextShort1: EnumValue
        DataTextShort2: EnumValue
        DataTextShort3: EnumValue
        DataTextShort4: EnumValue
        DataTextShort5: EnumValue
        ProfileAnswerQuestionId: EnumValue
        ProfilePageSurveyId: EnumValue
        DataProfileQuestionChoiceId: EnumValue
        PersonName: EnumValue
        TotalMentions: EnumValue
        VideoAnalyticsActivityHasIgnoredVideos: EnumValue
        VideoAnalyticsActivityHasVideosWithMachineTranscriptionOnly: EnumValue
        VideoAnalyticsActivityHasVideosWithHumanTranscriptionPending: EnumValue
        VideoAnalyticsActivityHasVideosWithoutTranscription: EnumValue
        VideoAnalyticsPersonHasIgnoredVideosInActivities: EnumValue
        VideoAnalyticsPersonHasVideosWithMachineTranscriptionOnlyInActivities: EnumValue
        VideoAnalyticsPersonHasVideosWithHumanTranscriptionPendingInActivities: EnumValue
        VideoAnalyticsPersonHasIgnoredVideosInGivenQuestions: EnumValue
        VideoAnalyticsPersonHasVideosWithMachineTranscriptionOnlyInGivenQuestions: EnumValue
        VideoAnalyticsPersonHasVideosWithHumanTranscriptionPendingInGivenQuestions: EnumValue
        VideoAnalyticsPersonHasVideosWithoutTranscriptionInActivities: EnumValue
        DataToolsPeopleHaveAnswersInGivenQuestions: EnumValue
        VideoToolsPeopleHaveAnswersInGivenQuestions: EnumValue
        VideoToolsPeopleHaveAnalyticsInGivenQuestions: EnumValue
        DataToolsEntityIsInGivenQuestions: EnumValue
        DataToolsThreadHasAnswersIsInGivenQuestions: EnumValue
        DataToolsPeopleHaveTextAnalyticsInActivity: EnumValue
        VideoToolsEntityIsInInGivenQuestions: EnumValue
        VideoToolsPeopleHaveTextAnalyticsInActivity: EnumValue
        VideoToolsPeopleHaveTextAnalytics: EnumValue
        TopicThreadHasGivenQuestions: EnumValue
        SummaryStatus: EnumValue
        TopicHasSummaries: EnumValue
        TopicHasSummariesInDomain: EnumValue
        DataToolsPeopleHaveSummaryInActivity: EnumValue
        ThreadInTopicIdMultiple: EnumValue
        ThreadHasSummaries: EnumValue
        ThreadHasGeneralKeyPoints: EnumValue
        AiResponsesAccountId: EnumValue
        AiResponsesDiscussionId: EnumValue
        AiResponsesTopicId: EnumValue
        AiResponsesRequestType: EnumValue
        AiResponsesCreateDate: EnumValue
        SummaryText: EnumValue
        KeyPointId: EnumValue
        PersonDiscussionId: EnumValue
        ThreadsWithoutContentTranslation: EnumValue
        ThreadsWithoutAnswerTranslation: EnumValue
        DiscussionGroupId: EnumValue
        HasPipelinesWithStatus: EnumValue
    }

    BrowseFilterExtraJoins: {
        ThreadSummary: EnumValue
        TaskMemberEngagements: EnumValue
    }

    CanvasBoardTypes: {
        NotAllowedToPinImages: EnumValue
        AllowedToPinProvidedImages: EnumValue
        AllowedToPinUploadedImages: EnumValue
        AllowedToPinProvidedAndUploadedImages: EnumValue
        AllowedToPinTextOnly: EnumValue
        AllowedToPinUploadedImagesWithSearch: EnumValue
        AllowedToPinProvidedAndUploadedImagesWithSearch: EnumValue
    }

    CardScoreScaleMarkingTypes: {
        Unknown: EnumValue
        NoMarkings: EnumValue
        CentreMarking: EnumValue
        NumericScoreMarkings: EnumValue
    }

    CardSortCustomGroupDetailTypes: {
        NeedDetails: EnumValue
        DoNotNeedDetails: EnumValue
    }

    CardSortCustomGroupsCountTypes: {
        Unlimited: EnumValue
        Restricted: EnumValue
    }

    CardSortDisplayTypes: {
        Text: EnumValue
        TextAndImage: EnumValue
        Image: EnumValue
    }

    CardSortGroupTypes: {
        Closed: EnumValue
        Open: EnumValue
        Hybrid: EnumValue
    }

    CardSortLimitTypes: {
        AllCards: EnumValue
        Between: EnumValue
    }

    CardSortRenderingTypes: {
        OneAtATime: EnumValue
        AllAtOnce: EnumValue
    }

    CardSortSortingTypes: {
        Natural: EnumValue
        Alphabetical: EnumValue
        Random: EnumValue
    }

    CommentsAdditionalVisibilityType: {
        Immediately: EnumValue
        IfTopicIsAnswered: EnumValue
    }

    CommentsByPeopleFilteringType: {
        NoFiltering: EnumValue
        PeopleMustBeTagged: EnumValue
        PeopleMustBeRelated: EnumValue
    }

    CommentsByTagsFilteringType: {
        NoFiltering: EnumValue
    }

    CommentsVisibilityType: {
        ShowAll: EnumValue
        HideAll: EnumValue
    }

    CommunityPageAccess: {
        Unknown: EnumValue
        AlwaysVisible: EnumValue
        LoggedInMembersOnly: EnumValue
        NotLoggedIn: EnumValue
    }

    CommunityPageHistoryState: {
        Staging: EnumValue
        Live: EnumValue
    }

    CommunityPageStartUpOptions: {
        Never: EnumValue
        Always: EnumValue
        IfLoggedIn: EnumValue
        IfNotLoggedIn: EnumValue
    }

    CommunityPageType: {
        BrochurePage: EnumValue
        MemberHomePage: EnumValue
        TermsAndCondition: EnumValue
        Custom: EnumValue
        Master: EnumValue
        PrivacyPolicy: EnumValue
        ParticipantTermsAndConditions: EnumValue
        SpamPolicy: EnumValue
        CookiePolicy: EnumValue
        SignUp: EnumValue
        LogIn: EnumValue
        Activity: EnumValue
        PasswordReset: EnumValue
        AccountActivation: EnumValue
        MemberProfile: EnumValue
        BlogArticle: EnumValue
        NoAccess: EnumValue
        TermsAndConditionsChangeSummary: EnumValue
        Unsubscribe: EnumValue
        TwoFactorAuth: EnumValue
        Referral: EnumValue
        CommunityMessage: EnumValue
        SecurityPolicy: EnumValue
    }

    CommunityWidgetVariableTypes: {
        Unknown: EnumValue
        String: EnumValue
        Boolean: EnumValue
        Image: EnumValue
        Content: EnumValue
        URL: EnumValue
        Integer: EnumValue
        Float: EnumValue
        Photoset: EnumValue
    }

    ContentEditorPagesTypes: {
        IntroductionPageOnly: EnumValue
        IntroductionAndFAQPage: EnumValue
    }

    ContentLengthTypes: {
        Undefined: EnumValue
        Characters: EnumValue
        Words: EnumValue
    }

    ContentMessageType: {
        DiscussionDeclined: EnumValue
        DiscussionOffline: EnumValue
        DiscussionNotOpenYet: EnumValue
        DiscussionComplete: EnumValue
        DiscussionQuotaOut: EnumValue
        DiscussionParticipantComplete: EnumValue
        IdeationReviewInProgress: EnumValue
        CommunityNotOpenYet: EnumValue
        CommunityOffline: EnumValue
        CommunityComplete: EnumValue
        CommunityDeclined: EnumValue
        CommunityQuotaOut: EnumValue
        CommunityParticipantComplete: EnumValue
        DashboardInstructions: EnumValue
        EmptyDashboard: EnumValue
        CommunityProjectQuotaOut: EnumValue
        ScreenerNotOpenYet: EnumValue
        SurveyComplete: EnumValue
        ScreenerFail: EnumValue
        QuotaFail: EnumValue
        CommunityScreenerScreenOut: EnumValue
        CommunityScreenerQuotaFail: EnumValue
        CommunityScreenerPassed: EnumValue
        ProjectScreenerScreenOut: EnumValue
        ProjectScreenerQuotaFail: EnumValue
        ProjectScreenerPassed: EnumValue
        DiscussionUnsubscribeCancelled: EnumValue
    }

    ContentTagSpecialType: {
        None: EnumValue
        VideoVerificationStart: EnumValue
        VideoVerificationAdvanced: EnumValue
        VideoVerificationBasic: EnumValue
        VideoVerificationFailed: EnumValue
        ChoiceTagForThread: EnumValue
    }

    ContentTagVisibilityType: {
        Hidden: EnumValue
        AdministratorsOnly: EnumValue
        Everyone: EnumValue
    }
    
    DateFormats: {
        Full: EnumValue
        MonthYear: EnumValue
        Year: EnumValue
    }

    DeletionModeTypes: {
        Available: EnumValue
        Removed: EnumValue
        Deleted: EnumValue
    }

    AutomaticProjectCleanupTypes: {
        None: EnumValue
        AnonymiseAllParticipants: EnumValue
        DeleteAllData: EnumValue
        DeleteTheEntireProject: EnumValue
    }

    DiscussionFilterTypes: {
        Unknown: EnumValue
        All: EnumValue
        MostRecent: EnumValue
        UnderConstruction: EnumValue
        Live: EnumValue
        RecentlyCompleted: EnumValue
        CreatedByWCSStuff: EnumValue
        CreatedByClients: EnumValue
        Removed: EnumValue
    }

    DiscussionPartialUpdateParts: {
        ModeratorAccess: EnumValue
        ModeratorNotifications: EnumValue
        NotificationTimes: EnumValue
        ObserverAccess: EnumValue
        ParticipantsFilteringForModerators: EnumValue
        ParticipantsFilteringForObservers: EnumValue
    }

    DiscussionType: {
        MultiTopic: EnumValue
        Homework: EnumValue
        Community: EnumValue
        Ideation: EnumValue
        Survey: EnumValue
        ExternalSurvey: EnumValue
        Recruitment: EnumValue
    }

    EmailBodyFormat: {
        Text: EnumValue
        Html: EnumValue
    }

    EmailSendGridStatus: {
        Processed: EnumValue
        Dropped: EnumValue
        Delivered: EnumValue
        Deferred: EnumValue
        Bounce: EnumValue
        Open: EnumValue
        Click: EnumValue
        SpamReport: EnumValue
        Unsubscribe: EnumValue
        GroupUnsubscribe: EnumValue
        GroupResubscribe: EnumValue
    }

    EmailStatus: {
        New: EnumValue
        Outbox: EnumValue
        Sending: EnumValue
        Sent: EnumValue
        Failed: EnumValue
        Retry: EnumValue
        Retry1: EnumValue
        Retry2: EnumValue
        Received: EnumValue
    }

    EmailType: {
        AccountActivation: EnumValue
        AdministratorActivation: EnumValue
        ResetPassword: EnumValue
        DiscussionEmail_All: EnumValue
        DiscussionEmail_Invited: EnumValue
        DiscussionEmail_Participants: EnumValue
        DiscussionEmail_Observer: EnumValue
        DiscussionEmail_Moderator: EnumValue
        DiscussionEmail_Declined: EnumValue
        DiscussionEmail_Reminder1: EnumValue
        DiscussionEmail_Reminder2: EnumValue
        DiscussionEmail_ToDo: EnumValue
        DiscussionOpenFail_Owner: EnumValue
        DiscussionOpenFail_Developers: EnumValue
        DiscussionInvoice: EnumValue
        AccountStatement: EnumValue
        DiscussionInvoice_Late: EnumValue
        VideoVerification: EnumValue
        ShowreelReadyEmail: EnumValue
        LinkForwarder: EnumValue
        ManualRedemptionInfo: EnumValue
        DiscussionEmail_Moderators_ToDo: EnumValue
        GlobalLoginLink: EnumValue
        DiscussionEmail_Screener: EnumValue
        Immediate_Response_On_Thread: EnumValue
        Participants_Anonymity_Completion_Email: EnumValue
        Community_SignUp: EnumValue
        SurveyInvitation: EnumValue
        SurveyReminder: EnumValue
        Community_Invited: EnumValue
        Community_All: EnumValue
        Community_Participants: EnumValue
        Community_Declined: EnumValue
        Community_Reminder1: EnumValue
        Community_Reminder2: EnumValue
        Community_Observer: EnumValue
        Community_Moderator: EnumValue
        Community_ToDo: EnumValue
        Community_Moderators_ToDo: EnumValue
        MakeParticipantComplete: EnumValue
        MakeCompleteParticipant: EnumValue
        MailingListEmail: EnumValue
        MailingListEmail_Reminder1: EnumValue
        MailingListEmail_Reminder2: EnumValue
        ParticipationTrackerFollowupEmail: EnumValue
        MandatoryProbeEmail: EnumValue
        ProjectExported: EnumValue
        ActivityExported: EnumValue
        ThreadsExported: EnumValue
        ProjectImported: EnumValue
        ActivityImported: EnumValue
        ThreadsImported: EnumValue
        ErrorLogging: EnumValue
        SMSVerification_AccountActivation: EnumValue
        TwoFactorCodeVerification_SMS: EnumValue
        TwoFactorCodeVerification_Email: EnumValue
        General: EnumValue
    }

    EngagementStatCacheStates: {
        Unknown: EnumValue
        Processing: EnumValue
        Ready: EnumValue
    }

    EngagementStatsActivityDisplayModes: {
        None: EnumValue
        OneMonth: EnumValue
        TwoMonths: EnumValue
        ThreeMonths: EnumValue
    }
    
    ExpenseTypes: {
        VideoProcessing: EnumValue
        Ai: EnumValue
        Transcription: EnumValue
        TextAnalysis: EnumValue
        Translation: EnumValue
        Others: EnumValue
    }
    
    ExportsSecurityModes: {
        None: EnumValue,
        ExcludeIdentifiableInfoOptional: EnumValue,
        ExcludeIdentifiableInfoMandatory: EnumValue
    }

    ExternalSurveyStatuses: {
        Unknown: EnumValue//0
        Started: EnumValue// = 1,
        CompleteSuccess: EnumValue// = 2,
        ScreenerFailed: EnumValue// = 3,
        QuotaFailed: EnumValue// = 4,
    }

    HeatMapOptionTypes: {
        Comments: EnumValue
        CommentsAndTags: EnumValue
    }

    IdeationStages: {
        Unknown: EnumValue
        IdeaCreation: EnumValue
        IdeaReview: EnumValue
        IdeaRating: EnumValue
    }

    InitialEndpointDataTypes: {
        Unknown: EnumValue
        Activities: EnumValue
        People: EnumValue
        Analysis: EnumValue
        Timeline: EnumValue
        Projects: EnumValue
        CommunityActivities: EnumValue
        SiteContent: EnumValue
        Attributes: EnumValue     
        AttributeData:EnumValue
        Subscriptions: EnumValue
        CommunityRegistrationForm: EnumValue
        CommunityProfileForms: EnumValue
        Screener: EnumValue
        DataTools: EnumValue
        VideoTools: EnumValue
        Clients: EnumValue
        MailingList: EnumValue
        ReportsAndDownloadFiles: EnumValue
        Rewards: EnumValue
        RewardsV2: EnumValue
        Audience: EnumValue
    }

    JourneyStageTypes: {
        Custom: EnumValue
        Fixed: EnumValue
    }

    MandatoryProbeEmailTriggerTypes: {
        None: EnumValue
        RequestResponse: EnumValue
        OnEachReply: EnumValue
    }

    MediaTypes: {
        YouTube: EnumValue
        Vimeo: EnumValue
        DirectUpload: EnumValue
        ImageURL: EnumValue
        ContentImage: EnumValue
    }

    MembershipStatusTypes: {
        Invited: EnumValue
        Member: EnumValue
        ScreenOut: EnumValue
        QuotaOut: EnumValue
        Declined: EnumValue
        Moderator: EnumValue
        Observer: EnumValue
        Completed: EnumValue
    }

    ModeratorDiscussionAccessTypes: {
        Analysis: EnumValue
        DataTools: EnumValue
        VideoTools: EnumValue
        Timeline: EnumValue
        Topics: EnumValue
        People: EnumValue
        Files: EnumValue
        Reports: EnumValue
        Emails: EnumValue
        SMS: EnumValue
    }

    ModeratorNotifyingType: {
        OnReplyToHisComment: EnumValue
        EveryComment: EnumValue
        EveryCommentAndReply: EnumValue
    }

    ModeratorsMarkAsReadCommentTypes: {
        MarkAsReadForEachModerator: EnumValue
        MarkAsReadForEveryone: EnumValue
    }

    /*
     * The list of pages where we add questions/attributes
     * This is mainly used by new survey question dialog ts model to construct a list of attributes that are allowed to be added to various types of forms
     */
    NewSurveyQuestionDialogPageTypes: {
        /**
         * We are adding questions to a survey topic/diary topic/VP topic form
         */
        SurveyForm: EnumValue

        /**
         * We are adding questions to global attributes page
         */
        GlobalAttributes: EnumValue
        
        /**
         * We are adding questions to a community registration form
         */
        CommunityRegistrationForm: EnumValue

        /**
         * We are adding questions to a community profile form
         */
        CommunityProfileForm: EnumValue

        /**
         * We are adding questions to a screener of an ad-hoc project
         */
        AdHocProjectScreener: EnumValue

        /**
         * We are adding questions to a screener of a community project
         */
        CommunityProjectScreener: EnumValue
    }
    
    NotificationEventType: {
        NewCommentToRead: EnumValue
        NewReplyOnMyThread: EnumValue
        NeedsModeration: EnumValue
        ReportedAsAbuse: EnumValue
        NewReplyOnFollowedThread: EnumValue
        NewReplyToRead: EnumValue
        MandatoryProbe: EnumValue
    }

    ObserverNotifyingType: {
        Never: EnumValue
        EveryComment: EnumValue
        EveryCommentAndReply: EnumValue
    }

    ObserverAccessTypes: {
        ViewOnly: EnumValue
        CanComment: EnumValue
        CanCommentAndModeratorNotified: EnumValue
    }

    OnlineStatusType: {
        Draft: EnumValue
        Offline: EnumValue
        Complete: EnumValue
        Open: EnumValue
        Demo: EnumValue
    }

    ParticipantsAnonymityType: {
        FirstNameLastNameInitial: EnumValue
        FirstNameInitialLastNameInitial: EnumValue
        FullName: EnumValue
        CompletelyAnonymous: EnumValue
        Nickname: EnumValue
        AutomaticNickname: EnumValue
    }

    ParticipantsCommentTypes: {
        OnlyVideo: EnumValue
        OnlyText: EnumValue
    }

    ParticipantsReplyTypes: {
        StandardReply: EnumValue
        OnlyVideo: EnumValue
    }

    ParticipantsTypes: {
        Known: EnumValue
        Unknown: EnumValue
        UnknownWithAnonymousCodes: EnumValue
    }

    PasswordPolicyResultTypes: {
        Success: EnumValue
        EmptyPassword: EnumValue
        InvalidLength: EnumValue
        InvalidDigitsCount: EnumValue
        InvalidSymbolsCount: EnumValue
        CommonPassword: EnumValue
        PasswordExpired: EnumValue
        PasswordUsedBefore: EnumValue
    }

    PeopleBrowseTypes: {
        Box: EnumValue
        Table: EnumValue
        Engagement: EnumValue
    }

    PhoneNumberTypes: {
        Mobile: EnumValue
        Fixed: EnumValue
        Any: EnumValue
    }

    PostCreatorRestrictionTypes: {
        Participant: EnumValue
        ParticipantAndModerator: EnumValue
        Moderator: EnumValue
    }

    PostsFilteringTypes: {
        NoFiltering: EnumValue
        FilterByTags: EnumValue
        FilterByText: EnumValue
    }

    ProfileQuestionAddressFormats: {
        StreetCityStatePostCodeCountry: EnumValue
        StreetCityPostCodeCountry: EnumValue
        CityStatePostCodeCountry: EnumValue
        CityPostCodeCountry: EnumValue
        StatePostCodeCountry: EnumValue
        PostCodeCountry: EnumValue
        PostCodeOnly: EnumValue
    }

    ProfileQuestionBlankType: {
        Unknown: EnumValue
        Freeform: EnumValue
        List: EnumValue
        Numeric: EnumValue
        Date: EnumValue
    }

    ProfileQuestionChoicesDisplayLayoutTypes: {
        DownThePage: EnumValue
        AcrossThePage: EnumValue
    }

    ProfileQuestionChoicesSortTypes: {
        NoSort: EnumValue
        AlphabeticallyASC: EnumValue
        AlphabeticallyDESC: EnumValue
        Randomized: EnumValue
    }

    ProfileQuestionContinueOptionTypes: {
        NoPause: EnumValue
        Pause: EnumValue
        PauseContinue: EnumValue
        PauseHideContinue: EnumValue
        PauseHideContinueOnPress: EnumValue
    }

    ProfileQuestionContinueTimerTypes: {
        Hidden: EnumValue
        Visible: EnumValue
    }

    ProfileQuestionFilterModes: {
        Exclude: EnumValue
        Include: EnumValue
    }

    ProfileQuestionImagesPickerTypes: {
        Uploaded: EnumValue
        Searched: EnumValue
        UploadedOrSearched: EnumValue
    }

    ProfileQuestionMultipleChoiceControlTypes: {
        Checkboxes: EnumValue
        ImageSelector: EnumValue
    }

    ProfileQuestionNameFormats: {
        Full: EnumValue
        FirstAndLast: EnumValue
        TitleAndLast: EnumValue
        Name: EnumValue
    }

    ProfileQuestionSingleChoiceControlTypes: {
        RadioButtons: EnumValue
        Dropdown: EnumValue
        Slider: EnumValue
        ImageSelector: EnumValue
    }

    ProfileQuestionSliderChoiceLabelTypes: {
        None: EnumValue
        MinMax: EnumValue
        MinMidMax: EnumValue
        All: EnumValue
        CustomMinMax: EnumValue
    }

    ProfileQuestionNumericSliderLabelTypes: {
        None: EnumValue
        MinMax: EnumValue
        MinMidMax: EnumValue
        All: EnumValue
        CustomMinMax: EnumValue
    }

    ProfileQuestionImageSelectorCaptionTypes: {
        Shown: EnumValue
        Hidden: EnumValue
    }

    ProfileQuestionChoicesRatingTypes: {
        Standard: EnumValue
        RatingScale: EnumValue
    }

    ProfileQuestionChoiceSelectionLimitModes: {
        Any: EnumValue
        All: EnumValue
        Between: EnumValue
    }
    
    ProfileQuestionChoiceVisibilityTagModeTypes: {
        HasTag: EnumValue,
        DoesNotHaveTag: EnumValue
    }

    ProfileQuestionMultipleChoiceSelectionLimitModes: {
        Any: EnumValue
        Between: EnumValue
    }
    
    ProfileQuestionSurveyResponseInclusionTypes: {
        Include: EnumValue
        Exclude: EnumValue
    }

    ProfileQuestionTextOptionsLimitTypes: {
        NoLimit: EnumValue
        CharacterLimit: EnumValue
        WordLimit: EnumValue
    }

    ProfileQuestionTitlePositionTypes: {
        AboveImage: EnumValue
        BelowImage: EnumValue
    }

    ProfileQuestionUrlParamAnswerTypes: {
        NotNeeded: EnumValue
        Optional: EnumValue
        Mandatory: EnumValue
    }

    ProfileQuestionTypes: {
        PrimaryTimeZone: EnumValue
        PrimaryName: EnumValue
        PrimaryMobileNumber: EnumValue
        PrimaryEmail: EnumValue
        PrimaryAddress: EnumValue
        ShortText: EnumValue
        LongText: EnumValue
        Date: EnumValue
        SingleChoice: EnumValue
        MultipleChoice: EnumValue
        TimeSelection: EnumValue
        Images: EnumValue
        Video: EnumValue
        Ranking: EnumValue
        Numeric: EnumValue
        HTMLContent: EnumValue
        Files: EnumValue
        StaticImages: EnumValue
        StaticVideo: EnumValue
        Audio: EnumValue
        Name: EnumValue
        TimeZone: EnumValue
        Address: EnumValue
        Email: EnumValue
        Phone: EnumValue
        BirthDate: EnumValue
        Websites: EnumValue
        Blanks: EnumValue
        Nickname: EnumValue
        Avatar: EnumValue
    }

    ProfileQuestionVisibilityTypes: {
        Always: EnumValue
        AtleastOneTag: EnumValue
        AllTags: EnumValue
        NoneOfTheseTags: EnumValue
    }
    ProjectDashboardToDoItemTypes: {
        Unknown: EnumValue
        HelpDocs: EnumValue
        PurchaseOrder: EnumValue
        ProjectIntro: EnumValue
        Activities: EnumValue
        Participants: EnumValue
        Moderators: EnumValue
        Observers: EnumValue
        BouncedEmails: EnumValue
        SendInvitations: EnumValue
        ResendInvitations: EnumValue
    }

    RedemptionRequestStatuses: {
        Unknown: EnumValue
        Pending: EnumValue
        Completed: EnumValue
        Rejected: EnumValue
        OnHold: EnumValue
    }

    RedemptionRequestTypes: {
        Unknown: EnumValue
        GiftPay: EnumValue
        BankTransfer: EnumValue
    }

    RegionDataType: {
        Unknown: EnumValue
        City: EnumValue
        State: EnumValue
        PostCode: EnumValue
        GPSCoordinate: EnumValue
    }

    ReportAndDownloadFileStatusTypes: {
        Waiting: EnumValue
        Processing: EnumValue
        Processed: EnumValue
        Failed: EnumValue
        Cancelled: EnumValue
        Protecting: EnumValue
    }
    
    ReportAndDownloadFileTypes: {
        Unknown: EnumValue
        WordTranscript: EnumValue
        ExcelTranscript: EnumValue
        ParticipantsExportExcel: EnumValue
        ResponseCountStats: EnumValue
        MediaDownloadZipFile: EnumValue
        ThreadSummariesWord: EnumValue
        ThreadSummariesExcel: EnumValue
        RawTextDataExport: EnumValue,
        AiThoughtsDataExportExcel: EnumValue
    }

    RuleConditionOperators: {
        Unknown: EnumValue
        Equal: EnumValue
        NotEqual: EnumValue
        Less: EnumValue
        LessOrEqual: EnumValue
        Greater: EnumValue
        GreaterOrEqual: EnumValue
        Between: EnumValue
        NotBetween: EnumValue
        BeginsWith: EnumValue
        NotBeginWith: EnumValue
        Contains: EnumValue
        NotContains: EnumValue
        EndsWith: EnumValue
        NotEndsWith: EnumValue
        In: EnumValue
        NotIn: EnumValue
        IsEmpty: EnumValue
        NotIsEmpty: EnumValue
    }

    RuleOperandDateValueOptions: {
        Date: EnumValue
        Years: EnumValue
    }

    RuleOperandAddressValueOptions: {
        FullAddress: EnumValue
        Street: EnumValue
        Suburb: EnumValue
        State: EnumValue
        PostCode: EnumValue
        Country: EnumValue
    }

    ScreenerActionTypes: {
        NoAction: EnumValue
        SendLink: EnumValue
        TakeToDiscussion: EnumValue
        SendLinkAndTakeToDiscussion: EnumValue,
    }

    SecuredFileActionTypes: {
        UploadStarted: EnumValue
        UploadFinished: EnumValue
        Download: EnumValue
        Delete: EnumValue
    }

    SecuredFileExpiryTypes: {
        Never: EnumValue
        Day1: EnumValue
        Day7: EnumValue
        Day30: EnumValue
        Day90: EnumValue
    }

    ServicePipelineStatusTypes: {
        Waiting: EnumValue
        Processing: EnumValue
        Processed: EnumValue
        Failed: EnumValue
    }

    ServicePipelineItemTaskTypes: {
        Unknown: EnumValue
        Translation: EnumValue
        TranslationDeletion: EnumValue
        Transcription: EnumValue
        AddPipeVideoProcessing: EnumValue
        AwsMediaConvertVideoProcessing: EnumValue
        Summarization: EnumValue
        ReplySuggestions: EnumValue
        TextAnalytics: EnumValue
        VideoAnalytics: EnumValue
        RebuildThreadContent: EnumValue
        KeyPointAnalysis: EnumValue
        ImageConversion: EnumValue
        ImageLqHqGeneration: EnumValue
        ImageToText: EnumValue
        AwsMediaConvertAudioProcessing: EnumValue
    }
    
    ServiceTaskStatus: {
        NeedsProcessing: EnumValue
        Processing: EnumValue
        Processed: EnumValue
        Failed: EnumValue
        Cancelled: EnumValue
    }

    SmartBannerItemType: {
        SpecificPerson: EnumValue
        SpecificTopic: EnumValue
        AllPosts: EnumValue
        RecentPosts: EnumValue
        UnreadPosts: EnumValue
    }

    SmartBannerGrouping: {
        None: EnumValue
        ByTopic_AllPosts: EnumValue
        ByTopic_UnreadPosts: EnumValue
        ByTopic_VideoTranscript: EnumValue
        ByTopic_SentimentAnalysis: EnumValue
        ByPerson_AllPosts: EnumValue
        ByPerson_UnreadPosts: EnumValue
        ByPerson_VideoTranscript: EnumValue
        ByPerson_SentimentAnalysis: EnumValue
        ByTime_AllPosts: EnumValue
        ByTime_UnreadPosts: EnumValue
        ByTime_VideoTranscript: EnumValue
        ByTime_SentimentAnalysis: EnumValue
        ByPerson_CardScoreAnalysis: EnumValue
        ByPerson_Videos: EnumValue
        ByTopic_Videos: EnumValue
        ByTime_Videos: EnumValue
        ByTopic_CardScoreAnalysis: EnumValue
        DataExplorer_AllPosts: EnumValue
        DataExplorer_UnreadPosts: EnumValue
        DataExplorer_SentimentAnalysis: EnumValue
        DataExplorer_PictureWall: EnumValue
        DataExplorer_VideoTranscripts: EnumValue
        CardScoreAnalysis_AverageByScale: EnumValue
        CardScoreAnalysis_AverageByCard: EnumValue
        DataExplorer_AudioTranscripts: EnumValue
        
        System_CommentsAndReplies: EnumValue
    }

    KeyTypes: {
        None: EnumValue
        PersonId: EnumValue
        TopicId: EnumValue
        UseDiscussionId_AllPosts: EnumValue
        UseDiscussionId_RecentPosts: EnumValue
        UseDiscussionId_UnReadPosts: EnumValue
        UseDiscussionId_PostedToday: EnumValue
        UseDiscussionId_PostedLastWeek: EnumValue
    }

    SmartGridViews: {
        Transcript: EnumValue
        Unread: EnumValue
        Highlights: EnumValue
        SentimentAnalysis: EnumValue
        Pictures: EnumValue
        Videos: EnumValue
        SentimentIntro: EnumValue
        AllSentiment: EnumValue
        SentimentPositive: EnumValue
        SentimentNegative: EnumValue
        Snippets: EnumValue
        VideoTranscript: EnumValue
        AudioTranscript: EnumValue
        DataTools: EnumValue
    }

    SMSStatus: {
        New: EnumValue
        Outbox: EnumValue
        Sending: EnumValue
        Sent: EnumValue
        Failed: EnumValue
        Delivered: EnumValue
        Undelivered: EnumValue
        Received: EnumValue
    }

    SpecialThreadTypes: {
        None: EnumValue
        SurveyAnswers: EnumValue
        HeatPoint: EnumValue
    }

    SurveyCommentTemplateTypes: {
        Automatically: EnumValue
        Custom: EnumValue
    }

    SurveyFormTypes: {
        StandardForm: EnumValue,
        Chat: EnumValue
    }

    SurveyRuleActionsTypes: {
        AddPersonTag: EnumValue
        Terminate: EnumValue
        TerminateAndAddPersonTag: EnumValue
        Redirect: EnumValue
        RedirectAndAddPersonTag: EnumValue
        RedirectAndTerminate: EnumValue
        RedirectAndTerminateAndAddPersonTag: EnumValue
    }

    TaskModerationTypes: {
        AllParticipants: EnumValue
        ParticipantsWithTags: EnumValue
    }

    TaskType: {
        Discussion: EnumValue
        DiscussionTopic: EnumValue
        Survey: EnumValue
        Homework: EnumValue
        Communities: EnumValue
        Ideation: EnumValue
        InternalSurvey: EnumValue
        ExternalSurvey: EnumValue
        AllTasks: EnumValue
    }

    TermsAndConditionsPositionTypes: {
        BelowTheIntroductionPage: EnumValue
        SeparateInitialPage: EnumValue
    }

    TextAnalyticsSentimentType: {
        All: EnumValue
        Negative: EnumValue
        Positive: EnumValue
    }
    
    ThreadMediaCaptionTypes: {
        Nothing: EnumValue
        Optional: EnumValue
        Required: EnumValue
    }

    ThreadMediaTypes: {
        Images: EnumValue
        Video: EnumValue
        Files: EnumValue
        Audio: EnumValue
    }

    ThreadTranscriptionStatuses: {
        unknown: EnumValue
        TranscriptionPending: EnumValue
        MachineTranscribed: EnumValue
        FirstEdit: EnumValue
        BeingEdited: EnumValue
        Edited: EnumValue
        Accurate: EnumValue
        NoTranscription: EnumValue
    }

    ThreadGoTranscriptStatuses: {
        TranscriptionPending: EnumValue
        Transcribed: EnumValue
        NoTranscription: EnumValue
        BasicTranscription: EnumValue
    }

    ThreadTypes: {
        Text: EnumValue
        Video: EnumValue
    }

    ThreadVisibility: {
        Public: EnumValue
        Private: EnumValue
        Group: EnumValue
        Tags: EnumValue
        PrivateMessage: EnumValue
        PrivateModsAndObs: EnumValue
    }

    TimeSelectionRangeTypes: {
        SingleTime: EnumValue
        StartEndTime: EnumValue
    }

    TopicCardTypes: {
        Image: EnumValue
        Text: EnumValue
    }

    TopicCompletionNotificationsTypes: {
        None: EnumValue
        Notified: EnumValue
        NotifiedIfNoResponseInCurrentDay: EnumValue
    }
    
    TopicCompletionType: {
        None: EnumValue
        PreviousTopics: EnumValue
        AllPreviousTopics: EnumValue
    }

    TopicContentTypes: {
        TextComments: EnumValue
        TextArticles: EnumValue
        CollectionOfDocuments: EnumValue
        HeatMap: EnumValue
        MultipleComments: EnumValue
        Survey: EnumValue
        VoxPop: EnumValue
        Ideation: EnumValue
        VirtualPinBoard: EnumValue
        InformationOnly: EnumValue
        VideoPresentation: EnumValue
        MediaOnly: EnumValue
        ScreenRecording: EnumValue
        ExternalSurvey: EnumValue
        InternalSurvey: EnumValue
        CardSort: EnumValue
        CardScore: EnumValue
        AudioUpload: EnumValue
    }

    TopicGroupRandomization: {
        NoReOrdering: EnumValue
        Randomized: EnumValue
        RandomOrderList: EnumValue
        OrderListWithTags: EnumValue
    }

    TopicNotifyingEmailReceiverTypes: {
        None: EnumValue
        AllModerators: EnumValue
        AllObservers: EnumValue
        AllModeratorsAndObservers: EnumValue
        SelectedPeople: EnumValue
    }

    TopicNotifyingEmailSettingsTypes: {
        Project: EnumValue
        Custom: EnumValue
    }

    TopicNotifyingEmailEventTypes: {
        NewPost: EnumValue
        NewReply: EnumValue
        NewReplyToMandatoryPost: EnumValue
    }

    TopicPartialUpdateParts: {
        TopicAvailability: EnumValue
        TopicCompletionNotificationsType: EnumValue
        TopicVisibility: EnumValue
        CommentsLimit: EnumValue
        TopicGroupShowNextOptions: EnumValue
        TitleFields: EnumValue
        CommentsVisibility: EnumValue
        TopicGroupHideUntilPrevious: EnumValue
        AllowedMediaTypes: EnumValue
        MediaCaptionType: EnumValue
        Attachments: EnumValue
        Icon: EnumValue
        TopicsOrder: EnumValue
        IdeationStage: EnumValue
        IdeationStagingStage: EnumValue
        SurveyAllowsMultipleResponses: EnumValue
        TopicClientLinks: EnumValue
    }

    TopicQuestionContinueOptionTypes: {
        NoPause: EnumValue
        Pause: EnumValue
    }

    TopicType: {
        Topic: EnumValue
        TopicGroup: EnumValue
        Poll: EnumValue
        Page: EnumValue
        Blog: EnumValue
        Screener: EnumValue
        Survey: EnumValue
    }

    TopicVisibilityTypes: {
        AllParticipants: EnumValue
        AtleastOneTag: EnumValue
        AllTags: EnumValue
        NoneOfTheseTags: EnumValue
    }

    UserActivityType: {
        WatchingVideo: EnumValue
    }

    UserFileTypes: {
        Unknown: EnumValue
        OriginalImage: EnumValue
        LQImage: EnumValue
        HQImage: EnumValue
        OriginalVideo: EnumValue
        LQVideo: EnumValue
        HQVideo: EnumValue
        VideoThumbnail: EnumValue
        Audio: EnumValue
        File: EnumValue
        PendingVideo: EnumValue
        PendingVideoThumbnail: EnumValue
    }

    UserFileState: {
        Unknown: EnumValue
        JustCreated: EnumValue
        Active: EnumValue
        Preactivated: EnumValue
    }

    VideoPresentationAdditionalContentVisibilityTypes: {
        Immediately: EnumValue
        AfterVideoHasBeenWatched: EnumValue
    }

    VideoPresentationEventTypes: {
        Undefined: EnumValue
        Play: EnumValue
        Pause: EnumValue
        Stop: EnumValue
        ProgressUpdate: EnumValue
    }

    VideoPresentationPlayerModes: {
        SmallPlayerOnly: EnumValue
        CouchModeAndSmallPlayer: EnumValue
    }

    VideoPresentationTypes: {
        PresentationOnly: EnumValue
        PresentationAndSurvey: EnumValue
    }

    VideoPresentationVideoStates: {
        Stopped: EnumValue
        Paused: EnumValue
        Playing: EnumValue
    }

    VideoUploadActivityVideoType: {
        Undefined: EnumValue
        GeneralVideo: EnumValue
        Selfie: EnumValue
    }

    TranscriptionTypes: {
        None: EnumValue
        Machine: EnumValue
    }

    PeopleSelectionModes: {
        All: EnumValue
        Selected: EnumValue
    }

    threadMediaTypes: {
        Nothing: EnumValue
        Images: EnumValue
        Video: EnumValue
        ImagesAndVideo: EnumValue
        Attachments: EnumValue
    }

    ShowreelPublishJobStatus: {
        SUBMITTED: EnumValue
        PROGRESSING: EnumValue
        COMPLETE: EnumValue
        ERROR: EnumValue
        CANCELED: EnumValue
    }

    ShowreelTitleType: {
        TitleOnly: EnumValue
        TitleAndDescription: EnumValue
        TextBlock: EnumValue
    }

    VideoAnalyticsChapterType: {
        ChapterWithClips: EnumValue
        ChapterWithTitle: EnumValue
    }

    ToolsTypes: {
        DataTools: EnumValue
        VideoTools: EnumValue
        AudioTools: EnumValue
    }
    
    ShowreelType: {
        Showreel: EnumValue
        ChapterOneFileDownload: EnumValue
        ClipDownload: EnumValue
    }
    
    ShowreelResolutionType: {
        SD: EnumValue
        HD: EnumValue
    }

    PrivateTopicAutoJumpTypes: {
        DoNothing: EnumValue
        Auto: EnumValue
        ShowMessage: EnumValue
    }

    PrivateTopicPostsVisibilityTypes: {
        Visible: EnumValue
        ReplacedByMessage: EnumValue
    }

    ProfileQuestionNumericControlTypes: {
        NumericField: EnumValue
        Slider: EnumValue
    }

    SummaryStatus: {
        NeedsProcessing: EnumValue
        Processed: EnumValue
        Failed: EnumValue
        Hidden: EnumValue
    }

    SummaryTargetType: {
        Thread: EnumValue
        Attachment: EnumValue
    }

    InsightType: {
        TextResponse: EnumValue
        ListValue: EnumValue
        Quotes: EnumValue
    }

    OpenAiReplySuggestionType: {
        ThankYou: EnumValue
        TellMeMore: EnumValue
    }

    OpenAiRequestType: {
        Unknown: EnumValue
        Ideas: EnumValue
        ReplySuggestion: EnumValue
        Summary: EnumValue
        KeyPoints: EnumValue
        Probe: EnumValue
        ProbeSuggestions: EnumValue
    }

    AnthropicAiModelTypes: {
        None: EnumValue;
        Haiku:EnumValue;
        Sonnet:EnumValue;
        Opus:EnumValue;
    }

    AnthropicAiRequestType: {
        Unknown: EnumValue;
        AiWorkbenchAnswerQuestion:EnumValue;
        AiWorkbenchIdentityTheme:EnumValue;
        AiWorkbenchHypothesis:EnumValue;
        AiWorkbenchHypothesisSupport:EnumValue;
        AiWorkbenchHypothesisOppose:EnumValue;
        AiWorkbenchAdditionalPrompts:EnumValue;
    }

    ModeratorReplySuggestionTypes: {
        UseProjectSetting: EnumValue
        NotUsed: EnumValue
        ByRequest: EnumValue
        Automatically: EnumValue
    }

    DiscussionModeratorReplySuggestionTypes: {
        NotUsed: EnumValue
        ByRequest: EnumValue
        Automatically: EnumValue
    }

    OpenAiRequestTimeIntervals: {
        Overall: EnumValue
        Today: EnumValue
        Yesterday: EnumValue
        LastWeek: EnumValue
        LastMonth: EnumValue
        LastThreeMonths: EnumValue
    }

    BrainstormItemType: {
        UserMessage: EnumValue
        UserMessageHidden: EnumValue
        AIResponse: EnumValue
        AIResponseDisplayOnly: EnumValue
    }
    
    Engines: {
        ChatGPT: EnumValue
        ChatGPT_3_5_Turbo: EnumValue
        Dall_e_3: EnumValue
    }
    
    AiProviders:{
        Anthropic: EnumValue,
        OpenAi: EnumValue
    }
    
    AiModelTypes:{
        /// <summary>
        /// No model specified
        /// </summary>
        None: EnumValue,// = 0,


        /// <summary>
        /// Claude 3 Haiku - Fast, Light, Cheapest Claude v3 model
        /// </summary>
        AnthropicHaiku: EnumValue,// = 100,

        /// <summary>
        /// Claude 3 Sonnet - Deprecated, replaced by version 3.5
        /// </summary>
        AnthropicSonnet: EnumValue,// = 101,

        /// <summary>
        /// Claude 3 Opus - Top-level performance, intelligence, fluency, and understanding
        /// </summary>
        AnthropicOpus: EnumValue,// = 102,



        /// <summary>
        /// Open AI Chat GPT 3.5 Turbo - A fast, inexpensive model for simple tasks
        /// </summary>
        OpenAiChatGpt35Turbo: EnumValue,// = 200,

        /// <summary>
        /// Open AI Chat GPT 4 Turbo - A large multimodal model (accepting text or image inputs and outputting text) that
        /// can solve difficult problems with greater accuracy than any of our previous models,
        /// thanks to its broader general knowledge and advanced reasoning capabilities
        /// </summary>
        OpenAiChatGpt4Turbo: EnumValue,// = 201,

        /// <summary>
        /// Open AI Chat GPT 4o Mini - Our affordable and intelligent small model for fast, lightweight tasks
        /// </summary>
        OpenAiChatGpt4OMini: EnumValue,// = 202,

        /// <summary>
        /// Open AI Chat GPT 4o - Our high-intelligence flagship model for complex, multi-step tasks
        /// </summary>
        OpenAiChatGpt4O: EnumValue,// = 203,

        /// <summary>
        /// Open AI DallE - A model that can generate and edit images given a natural language prompt
        /// </summary>
        OpenAiDallE: EnumValue,// = 204,



        /// <summary>
        /// Default model for Anthropic, will default to Haiku (cheapest)
        /// </summary>
        AnthropicDefault: EnumValue,// = 1000,

        /// <summary>
        /// Default Model for Open AI.  Will default to 
        /// </summary>
        OpenAiDefault: EnumValue,// = 1001,
    }

    TwoFactorAuthStatus: {
        Unrequested: EnumValue
        RequestedWaiting: EnumValue
        RequestedTimeout: EnumValue
        RequestedVerified: EnumValue
    }

    ToDoItemTypes: {
        ReceiveProjectDesign: EnumValue
        AddProjectDesignToDropbox: EnumValue
        CompleteProjectBuild: EnumValue
        ProjectBuildQualityCheck: EnumValue
        ProjectBuildAmends: EnumValue
        ProjectSignOffByClient: EnumValue
        ReceiveParticipantImportSheet: EnumValue
        AddParticipantImportSheetToFront: EnumValue
        ImportParticipants: EnumValue
        ImportModerators: EnumValue
        ImportObservers: EnumValue
        InvitationsSetToGoOut: EnumValue
        InvitationReminders: EnumValue
        ProjectLive: EnumValue
        CorrectBouncedEmails: EnumValue
        SendProjectUpdateEmail: EnumValue
        SendAnalysisEmail: EnumValue
        MoveQuoteOnHubspot: EnumValue
        ProjectClosed: EnumValue

        TranslatedGuideReceived: EnumValue
        TranslationsOverlayed: EnumValue
        Training: EnumValue
        ProjectCloseEmailSent: EnumValue
        GeneralToDo: EnumValue
        ProjectNote: EnumValue
    }

    ToDoItemPriorityTypes: {
        Medium: EnumValue
        High: EnumValue
    }

    ToDoItemStatuses: {
        ToDo: EnumValue
        WaitingOnClient: EnumValue
        Done: EnumValue
    }
    
    ActivitiesLayouts: {
        ActivityImagesAsIcons: EnumValue
        SmallActivityImages: EnumValue
        MediumActivityImages: EnumValue
        LargeActivityImages: EnumValue
    }

    HeroImageOverlayTypes: {
        None: EnumValue
        BasicText: EnumValue
        BasicTextWithBackground: EnumValue
        NumberBoxes: EnumValue
        NumberBoxesWithIcons: EnumValue
    }

    ToDoViewTypes: {
        Unfinished: EnumValue
        Overdue: EnumValue
        Finished: EnumValue
        All: EnumValue
    }

    ParticipantRewardTypes: {
        Unknown: EnumValue
        GiftPayRedemption: EnumValue
        ManualRedemption: EnumValue
        TopicCompleted: EnumValue
        AdminAwarded: EnumValue
        SurveyComplete: EnumValue
        SurveyQuotaFailed: EnumValue
        SurveyScreenerFailed: EnumValue
        ActivityPost: EnumValue
        ActivityReply: EnumValue
        GeneratedFromPrizeDraw: EnumValue
    }

    ParticipantRewardStatuses: {
        None: EnumValue
        GiftPaySending: EnumValue
        GiftPaySent: EnumValue
        GiftPayRedeemed: EnumValue
        GiftPayFailed: EnumValue
        ManualRedemption: EnumValue
    }

    DiscussionStages: {
        AwaitingGuide: EnumValue
        Programming: EnumValue
        AwaitingClientApproval: EnumValue
        ReadyToGoLive: EnumValue
        ProjectLive: EnumValue
        ProjectComplete: EnumValue
    }

   AIUsageProbabilityTypes: {
       FlaggedAsAI: EnumValue
       PossibleAIUsage: EnumValue
       UnlikelyAIUsage: EnumValue
       FlaggedAsHuman: EnumValue
   }

    AiWorkbenchThoughtStatuses: {
        Default: EnumValue
        Favourite: EnumValue
        Incorrect: EnumValue
    }

    AiWorkbenchQuestionTypes: {
        Question: EnumValue
        Theme: EnumValue
        Hypothesis: EnumValue
        TalkingPoint: EnumValue
    }

    BrainstormHistoryType: {
        IdeaPlayground: EnumValue
        AIWorkbenchQuestion: EnumValue
        ResponsesByActivity: EnumValue
        ResponsesByPerson: EnumValue
        ResponsesAll: EnumValue
    }

    ResponsesGroupings: {
        ByActivity: EnumValue
        ByPerson: EnumValue
        AllResponses: EnumValue
    }

    ResponsesViews: {
        Posts: EnumValue
        Unread: EnumValue
        Sentiment: EnumValue
        Pictures: EnumValue
        Videos: EnumValue
        Summaries: EnumValue
        KeyPoints: EnumValue
        Highlights: EnumValue
        Statistics: EnumValue
        Entities: EnumValue
        AverageByScale: EnumValue
        AverageByCard: EnumValue
        RatingMatrix: EnumValue
        Cards: EnumValue
        Categories: EnumValue
        ResultMatrix: EnumValue
        PopularPlacementsMatrix: EnumValue
        SimilarityMatrix: EnumValue
        WordCloud: EnumValue
    }
}

const enums: Enums & StringMap<StringMap<EnumValue>> = <any>{};

const config = (window as any).__wccconfig ?? {};
_(enums).extend(config.enums);

_(enums).extend({
    threadMediaTypes: {
        Nothing: 0,
        Images: 1,
        Video: 2,
        ImagesAndVideo: 3,
        Attachments: 4
    }
});

export default enums;