import { proxyLinkIfNeeded } from 'helpers/proxy';
import { IconImageOwner } from 'interfaces/iconImageOwner';
import {Observable, ObservableArray, PureComputed} from "knockout";

import tagsParser from 'managers/tagsParser';
import WCCEntity from './entity';
import {JSONTag, Tag} from "./tag";

//const Ideation = require('viewmodels/topicPage/ideation');

const { WccCDNUrl, now, isCommunity } = settings;

const highlightableActivityTypes = [
    enums.ActivityTypes.SocialPost.value,
    enums.ActivityTypes.MultiplePostGenerator.value,
    enums.ActivityTypes.SinglePage.value,
    enums.ActivityTypes.DiaryEntry.value,
    enums.ActivityTypes.AudioUpload.value,
    enums.ActivityTypes.VideoUpload.value,
    enums.ActivityTypes.WatchAndAnswer.value,
    enums.ActivityTypes.PinTask.value,
    enums.ActivityTypes.ScreenRecording.value,
];

export interface JSONSimpleTopic {
    DiscussionTopicId?: string
    DiscussionId?: string
    GroupId?: string
    Title?: string
    QuickSell?: string
    TopicType?: number
    ContentType?: number
    ActivityType?: number
    PostCreatorRestrictionType?: number
    TopicCompletionNotificationsType?: number
    VideoPresentationType?: number
    IdeationStage?: number
    IdeationStagingStage?: number
    Media?: string
    MediaImageHash?: string
    CommentsVisibility?: number
    CommentsAdditionalVisibility?: number
    IconClass?: string
    IconColor?: string
    IconStyle?: number
    IconBackgroundColor?: string
    IconBorderColor?: string
    ToDoCount?: number
    TotalPostsCount?: number
    RecentPostsCount?: number
    ToDoCountForObsPosts?: number
    TopicOrder?: number
    SurveyShowSummaryBox?:boolean

    IsGroup?: boolean
    IsFeatured?: boolean
    IsAnswered?: boolean
    VisibleAfter?: string
    VisibleBefore?: string
    CreateDate?: string
    TaskMemberCode?: string

    Availability?: number
    
    TopicVisibility?: number
    TopicVisibilityTags?: JSONTag[]

    RunSentimentAnalysis?: boolean | undefined

    MoodTagIsMandatory?: boolean

    ModeratorReplySuggestionType?: number
    DiscussionModeratorReplySuggestionType?: number
}

interface TopicStage {
    title: PureComputed<string>
}

export class SimpleTopic<T extends JSONSimpleTopic = JSONSimpleTopic> extends WCCEntity<T> implements IconImageOwner {
    protected _toDoCount: Observable<number>
    protected _totalPostsCount: Observable<number>
    protected _recentPostsCount: Observable<number>
    protected _toDoCountForObsPosts: Observable<number>
    protected _isAnswered: Observable<boolean>

    _json?: JSONSimpleTopic

    id: Observable<string | undefined>
    discussionTopicId: Observable<string | undefined>
    discussionId: Observable<string | undefined>
    groupId: Observable<string | undefined>

    topicOrder: Observable<number | undefined>

    title: PureComputed<string>
    quickSell: PureComputed<string>

    media: Observable<string | undefined>
    mediaImageHash: Observable<string | undefined>

    iconClass: Observable<string | undefined>
    iconColor: Observable<string | undefined>
    iconStyle: Observable<number | undefined>
    iconBackgroundColor: Observable<string | undefined>
    iconBorderColor: Observable<string | undefined>

    hasImage= false;
    iconOnly: boolean;
    isCircleIcon: boolean;
    iconBackgroundGradient: string;


    type: Observable<number | undefined>
    contentType: Observable<number | undefined>
    activityType: Observable<number | undefined>
    postCreatorRestrictionType: Observable<number | undefined>
    topicCompletionNotificationsType: Observable<number | undefined>
    videoPresentationType: Observable<number | undefined>
    commentsVisibility: Observable<number | undefined>
    commentsAdditionalVisibility: Observable<number | undefined>
    surveyShowSummaryBox: Observable<boolean | undefined>

    ideationStage: Observable<number>

    toDoCount: Observable<number>
    todoCountForUI: Observable<number>
    totalPostsCount: Observable<number>
    recentPostsCount: Observable<number>
    toDoCountForObsPosts: Observable<number>

    isGroup: Observable<boolean>
    isFeatured: Observable<boolean>
    isAnswered: Observable<boolean>

    topicAvailability: Observable<number>
    topicVisibility: Observable<number>
    visibilityTags: ObservableArray<Tag>
    
    visibleAfter: Observable<string | undefined>
    visibleBefore: Observable<string | undefined>
    createDate: Observable<string | undefined>

    taskMemberCode: Observable<string | undefined>

    visibleAfterDate: PureComputed<Date | undefined>
    visibleBeforeDate: PureComputed<Date | undefined>
    createDateVal: PureComputed<Date | undefined>    

    stages = ko.pureComputed(() => this.getStages());

    imageURL = ko.pureComputed(() => this.getImageUrl(this.media(), this.mediaImageHash()));
    fullImageURL = ko.pureComputed(() => this.getImageUrl(this.media(), this.mediaImageHash(), true));

    canHideQuickSell = ko.pureComputed(() => !this.quickSell() || this.quickSell() == this.title());
    showQuickSell = ko.pureComputed(() => !this.canHideQuickSell());

    canHideVisibilityDates = ko.pureComputed(() => !this.visibleAfter() && !this.visibleBefore());
    showVisibilityDates = ko.pureComputed(() => !this.canHideVisibilityDates());
    showNextDayLabel = ko.pureComputed(() => !this.visibleAfter() &&
        (this.topicAvailability() == enums.ActivityAvailabilities.AvailableNextDay.value || this.topicAvailability() == enums.ActivityAvailabilities.AvailableSameDay.value));
    
    showVisibilityTags = ko.pureComputed(() => this.visibilityTags().length > 0);

    isOptional = ko.pureComputed(() => this.topicCompletionNotificationsType() === enums.TopicCompletionNotificationsTypes.None.value);
    shouldBeAnswered = ko.pureComputed(() => !settings.isAdmin && !this.isOptional() && !this.isAnswered());
    isVisible = ko.pureComputed(() => this.checkIfVisible());

    isTextCommentsTopic = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.TextComments.value);
    isHeatMap = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.HeatMap.value);
    isMediaOnly = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.MediaOnly.value);
    isMultipleComments = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.MultipleComments.value);
    isCollectionOfDocuments = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.CollectionOfDocuments.value);
    isSurvey = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.Survey.value);
    isIdeation = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.Ideation.value);
    isPinBoard = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.VirtualPinBoard.value);
    isInformationOnlyTopic = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.InformationOnly.value);
    isVideoPresentation = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.VideoPresentation.value);
    isVoxPop = ko.pureComputed(() => this.contentType() === enums.TopicContentTypes.VoxPop.value);
    isScreenRecording = ko.pureComputed(() => this.contentType() == enums.TopicContentTypes.ScreenRecording.value);
    isAudioRecording = ko.pureComputed(() => this.contentType() == enums.TopicContentTypes.AudioUpload.value);
    isSurveyLike = ko.pureComputed(() => this.isSurvey() 
        || (this.activityType() == enums.ActivityTypes.WatchAndAnswer.value) 
        || (this.activityType() == enums.ActivityTypes.DiaryEntry.value));
    isHomework = settings.discussionType == enums.DiscussionType.Homework.value;

    // we always allow mods and admins to upload media
    imagesAreAllowed = true;
    videoIsAllowed = true;
    
    isSelected = ko.observable(false);
    isHighlightable: boolean;
    runSentimentAnalysis: boolean;
    
    noSurveyStat = true;

    moderatorReplySuggestionType: Observable<number>
    discussionModeratorReplySuggestionType: Observable<number>

    cCanHaveSuggestionsUI = ko.pureComputed(() =>
        this.moderatorReplySuggestionType() !== enums.ModeratorReplySuggestionTypes.NotUsed.value &&
        (this.moderatorReplySuggestionType() !== enums.ModeratorReplySuggestionTypes.UseProjectSetting.value
            || this.discussionModeratorReplySuggestionType() !== enums.DiscussionModeratorReplySuggestionTypes.NotUsed.value));
    
    //ideation: typeof Ideation;

    constructor(data?: T) {
        super();

        const jsonTopic = <JSONSimpleTopic | undefined>data;

        this._json = jsonTopic;

        this.id = this.createField(jsonTopic, 'DiscussionTopicId');
        this.discussionId = this.createField(jsonTopic, 'DiscussionId');
        this.discussionTopicId = this.id;
        this.groupId = this.createField(jsonTopic, 'GroupId');

        this.topicOrder = this.createField(jsonTopic, 'TopicOrder');

        this.quickSell = this.createField(jsonTopic, 'QuickSell', '').mapSingle(s => tagsParser.processTags(s)).trim();
        this.title = this.createField(jsonTopic, 'Title', '').mapNotNull(s => tagsParser.processTags(s)).trim();

        this.type = this.createField(jsonTopic, 'TopicType');
        this.contentType = this.createField(jsonTopic, 'ContentType');
        this.activityType = this.createField(jsonTopic, 'ActivityType');
        this.postCreatorRestrictionType = this.createField(jsonTopic, 'PostCreatorRestrictionType');
        this.topicCompletionNotificationsType = this.createField(jsonTopic, 'TopicCompletionNotificationsType');
        this.videoPresentationType = this.createField(jsonTopic, 'VideoPresentationType');
        this.commentsVisibility = this.createField(jsonTopic, 'CommentsVisibility');
        this.commentsAdditionalVisibility = this.createField(jsonTopic, 'CommentsAdditionalVisibility');
        this.surveyShowSummaryBox = this.createField(jsonTopic, 'SurveyShowSummaryBox');

        this.media = this.createField(jsonTopic, 'Media');
        this.mediaImageHash = this.createField(jsonTopic, 'MediaImageHash');
        
        this.isGroup = this.createField(jsonTopic, 'IsGroup', <boolean>false);
        this.isFeatured = this.createField(jsonTopic, 'IsFeatured', <boolean>false);
        this._isAnswered = this.createField(jsonTopic, 'IsAnswered', <boolean>false);

        const activityTypeObj = _.find(enums.ActivityTypes, t => t.value == this.activityType()) 
            ?? WCC.Enums.ActivityTypes.SocialPost;
        this.iconClass = this.createField(jsonTopic, 'IconClass');
        if(!this.iconClass()) {
            this.iconClass(this.isGroup() ? enums.ActivityTypes.ActivityGroup.icon : activityTypeObj.icon);
        }
        this.iconColor = this.createField(jsonTopic, 'IconColor');
        this.iconStyle = this.createField(jsonTopic, 'IconStyle');
        this.iconBackgroundColor = this.createField(jsonTopic, 'IconBackgroundColor');
        this.iconBorderColor = this.createField(jsonTopic, 'IconBorderColor');

        const ideationStage = this.createField(jsonTopic, 'IdeationStage', 0);
        const ideationStagingStage = this.createField(jsonTopic, 'IdeationStagingStage', 0);

        this.ideationStage = settings.isStaging ? ideationStagingStage : ideationStage;

        this._toDoCount = this.createField(jsonTopic, 'ToDoCount', 0);
        this._totalPostsCount = this.createField(jsonTopic, 'TotalPostsCount', 0);
        this._recentPostsCount = this.createField(jsonTopic, 'RecentPostsCount', 0);
        this._toDoCountForObsPosts = this.createField(jsonTopic, 'ToDoCountForObsPosts', 0);        

        this.createDate = this.createField(jsonTopic, 'CreateDate');
        this.visibleAfter = this.createField(jsonTopic, 'VisibleAfter');
        this.visibleBefore = this.createField(jsonTopic, 'VisibleBefore');
        this.topicAvailability = this.createField(jsonTopic, 'Availability', enums.ActivityAvailabilities.Available.value);
        
        this.topicVisibility = this.createField(jsonTopic, 'TopicVisibility', enums.TopicVisibilityTypes.AllParticipants.value);
        this.visibilityTags = ko.observableArray(_.map(jsonTopic?.TopicVisibilityTags ?? [], t => new Tag(t)));

        this.taskMemberCode = this.createField(jsonTopic, 'TaskMemberCode');

        this.visibleAfterDate = this.visibleAfter.mapNotNull(str => new Date(str));
        this.visibleBeforeDate = this.visibleBefore.mapNotNull(str => new Date(str));
        this.createDateVal = this.createDate.mapNotNull(str => new Date(str));

        this.moderatorReplySuggestionType = this.createField(jsonTopic, 'ModeratorReplySuggestionType', enums.ModeratorReplySuggestionTypes.NotUsed.value);
        this.discussionModeratorReplySuggestionType = this.createField(jsonTopic, 'DiscussionModeratorReplySuggestionType', enums.DiscussionModeratorReplySuggestionTypes.NotUsed.value);

        this.toDoCount = ko.pureComputed({
            read: () => this.hasNotifications(this.type()) ? this._toDoCount() : 0,
            write: v => this._toDoCount(v)
        });

        this.totalPostsCount = ko.pureComputed({
            read: () => this.hasNotifications(this.type()) ? this._totalPostsCount() : 0,
            write: v => this._totalPostsCount(v)
        });

        this.recentPostsCount = ko.pureComputed({
            read: () => this.hasNotifications(this.type()) ? this._recentPostsCount() : 0,
            write: v => this._recentPostsCount(v)
        });

        this.toDoCountForObsPosts = ko.pureComputed({
            read: () => this.hasNotifications(this.type()) ? this._toDoCountForObsPosts() : 0,
            write: v => this._toDoCountForObsPosts(v)
        });

        this.isAnswered = ko.pureComputed({
            read: () => this._isAnswered(),
            write: v => this._isAnswered(v)
        });

        this.todoCountForUI = ko.pureComputed(() => this.shouldBeAnswered() ? 1 : this.toDoCount());
        this.isHighlightable = highlightableActivityTypes.indexOf(this.activityType() ?? -1) !== -1;
        
        this.runSentimentAnalysis = jsonTopic?.RunSentimentAnalysis == true;
        
        this.hasImage = !!jsonTopic?.Media;
        this.iconOnly = this.iconStyle() === WCC.Enums.ActivityIconStyles.Default.value;
        this.isCircleIcon = this.iconStyle() === WCC.Enums.ActivityIconStyles.Circle.value;
        this.iconBackgroundGradient = this.iconBackgroundColor() != 'none' ? this.iconBackgroundColor() ?? '' : '';
        if(!this.iconOnly && this.iconBackgroundColor() !== 'none') {
            const baseColor = this.iconBackgroundColor() ?? '';
            const darkenColor = system.lightenDarkenColor(baseColor, -5);
            this.iconBackgroundGradient = `linear-gradient(45deg, ${baseColor} 0%, ${darkenColor} 100%)`;
        }
        
        /*if (this.contentType() == enums.TopicContentTypes.Ideation.value) {
            this.ideation = new Ideation(this);
        }*/
    }

    getRoute(legacy = false) {
        switch (this.type()) {
            case enums.TopicType.Topic.value:
                if (isCommunity() || legacy)
                    return `#topic/${this.discussionId()}/${this.discussionTopicId()}`;

                return `#activity/${this.discussionId()}/${this.discussionTopicId()}`;
            case enums.TopicType.Blog.value:
                return `#community/blogs/${this.discussionTopicId()}`;
        }
    }

    private getImageUrl(mediaUrl?: string, mediaHash: string = '', full: boolean = false): string | undefined {
        if (mediaUrl == undefined)
            return undefined;

        let url;

        if (mediaUrl.indexOf('//') == 0) {
            url = mediaUrl;
        } else {
            const postfix = full ? 'box_' : 'small_';

            url = //(WccCDNUrl + '/taskimages/' + postfix + mediaUrl).toLowerCase() + '?rnd=' + mediaHash;
            settings.wccApiUrl + '/api/resources/taskimages/' + mediaUrl + '?full='+full;
        }

        return proxyLinkIfNeeded(url);
    }

    private checkIfVisible() {
        const visibleAfterDate = this.visibleAfterDate();
        const visibleBeforeDate = this.visibleBeforeDate();

        return (visibleAfterDate == null || visibleAfterDate <= now()) &&
            (visibleBeforeDate == null || visibleBeforeDate >= now());
    }

    private getStages(): Array<TopicStage> {
        var title = this.quickSell.default(this.title, _.any);

        switch (this.contentType()) {
            case enums.TopicContentTypes.Ideation.value:
                if (this.postCreatorRestrictionType() !== enums.PostCreatorRestrictionTypes.Moderator.value) {
                    return [
                        { title: title.mapSingle(title => title + ' (' + labels.IdeaCreation + ')') },
                        { title: title.mapSingle(title => title + ' (' + labels.Rating + ')') }
                    ];
                }

                break;
        }

        return [
            { title: title }
        ];
    }

    private hasNotifications(type?: number) {
        switch (type) {
            case enums.TopicType.TopicGroup.value:
            case enums.TopicType.Topic.value:
                return true;
        }

        return false;
    }
}