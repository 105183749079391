import { DateTime, DateTimeUnit, DurationLike, WeekdayNumbers, Zone } from 'luxon';
import { Time } from '../models/time';
import { getSetting } from 'helpers/settings';

export module DateHelpers {
    export const timeFormat = 'HH:mm';
    export const datetimeFormat = 'd MMMM yyyy HH:mm';

    export const milisecondsInMinute = 1000 * 60;
    export const milisecondsInHour = milisecondsInMinute * 60;
    export const milisecondsInDay = milisecondsInHour * 24;
    export const milisecondsInWeek = milisecondsInDay * 7;

    export const defaultIntervalDuration = 30;
    export const defaultIntervalGap = 15;

    export function toTwoDigitsValue(value: number) {
        return value > 99 ? '99' :
            value > 9 ? value.toString() :
                value > 0 ? `0${value}` : '00';
    }

    export function formatSeconds(totalSeconds: number) {
        let sign = '';

        if (totalSeconds < 0) {
            sign = '-';
            totalSeconds = -totalSeconds;
        }

        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds - minutes * 60);

        const minutesStr = toTwoDigitsValue(minutes);
        const secondsStr = toTwoDigitsValue(seconds);

        return `${sign}${minutesStr}:${secondsStr}`;
    }

    /**
     * Returns a friendly string for future dates
     * Examples:
     * - Tomorrow 9am if the interval is 1 day
     * - Thursday 9am if the interval is within this week
     * - The full date otherwise
     * @param date
     */
    export function friendlyStringForFuture(date: Date) {
        const currentDate = moment().startOf('day'),
            futureDate = moment(date);

        if (futureDate <= currentDate)
            return null;

        const diff = futureDate.diff(currentDate, 'days');

        if (diff == 0 || diff == 1) {
            const time = moment(futureDate).format('hh:mm a');

            if (diff == 0) {
                return "Today, " + time;
            }

            if (diff == 1) {
                return "Tomorrow, " + time;
            }
        }

        if (diff <= 7) return moment(futureDate).format('dddd, hh:mm a');

        return moment(futureDate).format(getSetting('DateTimeDisplayFormatFullForMoment'));
    }

    /**
    * Simple helper function to add days to a date
    * @param days
    * @param date
    */
    export function addDaysToDate(days: number, date: Date) {
        const futureDate = new Date(date);
        futureDate.setDate(futureDate.getDate() + days);

        return futureDate;
    }

    export function addMinutes(date: Date, amount = 1) {
        return new Date(date.getTime() + milisecondsInMinute * amount);
    }

    export function addDays(date: Date, amount = 1) {
        return new Date(date.getTime() + milisecondsInDay * amount);
    }

    export function addWeeks(date: Date, amount = 1) {
        return addDays(date, 7 * amount);
    }

    export function toMinutes(miliseconds: number) {
        return miliseconds / milisecondsInMinute;
    }

    export function getWeekday(date: Date, timezone: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).weekday;
    }

    export function getStartOf(date: Date, unit: DateTimeUnit, timezone?: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).startOf(unit).toJSDate();
    }

    export function getEndOf(date: Date, unit: DateTimeUnit, timezone?: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).endOf(unit).toJSDate();
    }

    export function getTimeOfTheDay(date: Date, time: Time) {
        return DateTime.fromJSDate(date, { zone: time.timezone })
            .set({ hour: time.hours, minute: time.minutes, second: time.seconds, millisecond: time.miliseconds })
            .toJSDate();
    }

    export function getFirstDayOfTheMonth(date: Date, day: number, timezone: Zone) {
        const startOfTheMonth = DateTime.fromJSDate(getStartOf(date, 'month', timezone), { zone: timezone });
        const startOfTheMonthDay = startOfTheMonth.weekday % 7;

        if (startOfTheMonthDay < day)
            return startOfTheMonth.plus({ days: day - startOfTheMonthDay }).toJSDate();
        else if (startOfTheMonthDay > day)
            return startOfTheMonth.plus({ days: 7 + day - startOfTheMonthDay }).toJSDate();
        else
            return startOfTheMonth.toJSDate();
    }

    export function getLastDayOfTheMonth(date: Date, day: number, timezone: Zone) {
        const endOfTheMonth = DateTime.fromJSDate(getEndOf(date, 'month', timezone), { zone: timezone });
        const endOfTheMonthDay = endOfTheMonth.weekday % 7;

        if (endOfTheMonthDay < day)
            return endOfTheMonth.plus({ days: day - endOfTheMonthDay - 6 }).toJSDate();
        else if (endOfTheMonthDay > day)
            return endOfTheMonth.plus({ days: day - endOfTheMonthDay }).toJSDate();
        else
            return endOfTheMonth.toJSDate();
    }

    export function add(date: Date, duration: DurationLike, timezone?: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).plus(duration).toJSDate();
    }

    export function subtract(date: Date, duration: DurationLike, timezone?: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).minus(duration).toJSDate();
    }

    export function format(date: Date, format: string, timezone?: Zone) {
        return DateTime.fromJSDate(date, { zone: timezone }).toFormat(format);
    }

    export function min(...dates: Array<Date>) {
        return dates.reduce((result, date) => date.getTime() < result.getTime() ? date : result);
    }

    export function max(...dates: Array<Date>) {
        return dates.reduce((result, date) => date.getTime() > result.getTime() ? date : result);
    }

    export function getDistance(date1: Date, date2: Date) {
        return Math.abs(date1.getTime() - date2.getTime());
    }    

    export function parseDate(value: string | undefined) {
        return value != undefined ? new Date(value) : undefined;
    }
    
    export function getTimeZoneInfo(removeBraces: boolean = false) {
        let dateStr = new Date().toString();
        if (removeBraces) {
            dateStr = dateStr.replace('(', '').replace(')', '');
        }
        const dateParts = dateStr.split('GMT');
        if (dateParts.length > 1) {
            return dateParts[1];
        }
        return '';
    }
}